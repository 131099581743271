@import 'src/styles/basics';

.title {
  color: var(--primary-main8) !important;
  margin: 35px 0 15px;
  font-weight: $dashboardTitleFontWeight !important;
  font-size: $dashboardTitleFontSize !important;
  background: transparent !important;
  border-color: transparent !important;
}

.root {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 10px;
  padding: 0 1rem;
  transition: all 200ms ease-in-out;
  max-height: 27.5rem;
  overflow: auto;

  &.inActive {
    opacity: 0.5 !important;
  }
}
