@import 'src/styles/basics';

.root {
  display: flex;
  align-items: center;
  gap: 1rem;
  height: 3rem;
  width: 28.125rem;
  padding: 0 1rem;
  border-bottom: 1px solid var(--neutral5);
  font-family: 'Inter', sans-serif;

  transition: all 0.3ms ease-in-out;

  .icon {
    color: var(--neutral5);
  }

  .clearSearch {
    display: none;
  }

  .input {
    border: 0;
    background: transparent;
    width: 100%;
    font-size: 1rem;
    color: var(--neutral1);
    font-weight: 400;
    line-height: 150%;

    &::placeholder {
      font-family: 'Inter', sans-serif;
      color: var(--neutral5);
      font-size: 15px;
      font-style: italic;
      font-weight: 400;
      line-height: 120%; /* 18px */
      letter-spacing: -0.15px;
    }

    // When the input is not empty
    &:not(:placeholder-shown) + .clearSearch {
      display: block;
      color: var(--neutral5);
      border-radius: 50%;

      &:hover {
        color: var(--neutral1);
      }
    }
  }

  &:hover {
    border-bottom: 1px solid var(--neutral4);
    background: rgba(255, 255, 255, 0.04);

    .icon {
      color: var(--neutral4);
    }

    .input::placeholder {
      color: var(--neutral4);
    }
  }

  &:focus-within {
    border-bottom: 1px solid var(--neutral1);
    background: rgba(0, 0, 0, 0.08);

    .icon {
      color: var(--neutral1);
    }

    .input::placeholder {
      opacity: 0;
    }
  }
}
