@import 'src/styles/basics';
.root {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: var(--margin-2);

  label {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.0075rem;
    padding-bottom: var(--margin-1);
    color: var(--neutral6);
  }
}

.error {
  color: var(--red-medium);
}

.noMarginBottom {
  margin-bottom: 0;
}

.required {
  color: var(--red-medium);
}
