@import 'src/styles/basics';

.root {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.greyWrapper {
  align-items: center;
  padding: 0;
}
.semiTransparentWrapper {
  background-color: rgba($white, 0.18);
  border: 1px solid rgba($white, 0.27);
}

.input {
  border: none;
  width: 100%;
}

.input::placeholder {
  color: $inputEditorPlaceholder;
}

.error {
  text-align: left;
  margin-top: 4px;
}
