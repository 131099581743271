.root {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.44rem;

  h1 {
    color: var(--primary-main8) !important;
    font-weight: 700 !important;
    letter-spacing: -0.28px;
    text-align: center;
  }
}

.back {
  margin-top: 2.26rem;
}
