@import 'src/styles/basics';

.content {
  text-align: center;
}

.buttons {
  display: flex;
  margin-top: 15px;
}

.button {
  margin-right: 5px;
  margin-left: 5px;
}

.alert {
  margin-top: 1rem;
  text-align: center;
}
