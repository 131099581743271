@import 'src/styles/basics';

.root {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  z-index: 11;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  max-width: 600px;
  width: 50%;
  border-radius: 20px;
  min-height: 100px;
  background: $modalBackground;
  padding: 30px;
  max-height: 90vh;
  overflow: auto;
  position: relative;

  h5 {
    text-align: center;
    color: var(--primary-main8);
    font-size: 24px;
    font-weight: bold;
  }
}

.body {
  margin-top: 20px;
}

.close {
  position: absolute;
  right: 40px;
  top: 40px;
  z-index: 1;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 300ms ease;

  &:hover {
    opacity: 1;
  }
}
