@import 'src/styles/basics';

.root {
  display: flex;
  color: var(--primary-main9);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.1rem;
  letter-spacing: -0.01rem;
  align-items: center;
  margin-bottom: 0.5rem;
  flex-wrap: wrap;

  a {
    color: var(--primary-main6);
    font-weight: 500;
    cursor: pointer;
  }

  .arrow {
    color: var(--neutral6);
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 0.75rem;
    margin: 0 0.38rem 0 0.25rem;
  }
}

.path {
  align-items: center;
  display: flex;
  margin: 5px 0;
}

.clickAbleLink {
  background: none;
  border: 0;
}
