@import 'src/styles/basics';

.root {
  padding: 3.5px;
  margin: 0 3px;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: $updatedIndicationColor;
}
