@import 'src/styles/basics';

.root {
  position: fixed;
  top: var(--header-height);
  bottom: 0;
  min-width: 500px;
  width: 40vw;
  background: white;
  right: -40vw;
  z-index: 9;
  box-shadow: -5px 10px 30px 0 rgba(0, 0, 0, 0.2);
  transition: all 500ms ease-in-out;
  overflow: scroll;
  padding: 50px 50px 25px;
  transform: translateX(45vw);

  &.open {
    transform: translateX(-40vw);
  }
}

.header {
  display: flex;
  font-size: $headerFontSize;
  font-weight: $headerFontWeight;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  color: var(--primary-main8);

  .externalSyncIcon {
    margin-right: 15px;
    width: 41px;
    height: 40px;
    align-self: center;
  }
  .text {
    display: flex;
    flex-direction: column;
  }
  .titleWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    h2 {
      color: var(--primary-main8);
      font-size: 24px;
    }
  }

  .disclaimer {
    font-size: 12px;
    font-weight: 400;
    line-height: 120%;
  }
}

.category {
  font-size: $subHeaderFontSize;
  font-weight: $subHeaderFontWeight;
  line-height: 3.05;
  color: var(--primary-main8);
  margin-bottom: -10px;
}

.close {
  position: absolute;
  right: 50px;
  top: 15px;
  cursor: pointer;
}

.searchWrapper {
  background: rgba($searchInput, 0.2);
  height: 45px;
  border-radius: 7px;
  flex: 1;
  color: white;
  display: flex;
  align-items: center;
  padding: 0 16px;
  position: relative;
}

.icon {
  height: 25px;
  width: 25px;
}

.input {
  border: 0;
  border-radius: 10px;
  background: transparent;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  padding-left: 60px;
  color: $searchText;
}

.tagIconsWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}

.buttons {
  display: flex;
}

.rightButton {
  margin: 20px 10px 0 -20px;
  height: 40px;
}

.leftButton {
  margin: 20px 30px 0 0;
  height: 40px;
  max-width: 50%;
}

.tagsContainer {
  display: flex;
  margin-bottom: 5px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.knowledgeGroups {
  .category {
    font-size: 19px;
    font-weight: 700;
    line-height: 3.05;
    color: var(--primary-main8);
  }
}
