@import 'src/styles/basics';

.root {
  min-width: 170px !important;
}

.addButton {
  margin: 3px 7px 7px;
}

.popup {
  z-index: 9999;
}
