@import 'src/styles/basics';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 75vh;
}
.button {
  flex: 0;
}

.icon {
  height: 20px;
  width: 20px;
  margin: 10px 10px 10px 0px;
}

.callToAction {
  color: $primary;
  text-align: center;
  font-weight: 300;
  font-size: 20px;
  white-space: pre-line;
  margin-bottom: 10px;
}
