.notification {
  position: relative;
  display: flex;
  padding: 0.75rem var(--margin-2);
  background: var(--neutral1);
  justify-content: space-between;
  align-items: center;
  color: var(--primary-main8);
  font-family: Inter, sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.045rem;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.06);
}
