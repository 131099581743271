@import 'src/styles/basics';

.root {
  display: flex;
  flex-direction: row;
  gap: 50px;
  width: 100%;
  height: 100%;
  max-height: calc(100vh - var(--header-height));
  max-width: 100%;
  overflow: hidden;
  padding: $page-padding;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
