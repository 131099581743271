.root {
  background: var(--white20);
  border-radius: var(--button-radius);
  margin-left: var(--margin-1);
  border: 0;
  padding: var(--margin-1) var(--margin-5);
  color: var(--white);
  font-weight: 800;
  font-size: var(--font-size-label);
  transition: all 0.2s ease-in-out;
  position: relative;
  font-family: 'Inter', sans-serif;
  min-width: max-content;

  &:hover {
    transform: scale(1.05);

    &::before {
      animation: shake 0.5s;
      animation-iteration-count: infinite;
    }
  }

  &::before {
    content: '';
    display: block;
    height: 3.8rem;
    width: 3.8rem;
    position: absolute;
    left: -1.8rem;
    bottom: -1.2rem;
    background: url('../../../images/panda/panda-tail.png') no-repeat center;
    background-size: contain;
    transition: transform 0.5s quadratic-bezier(0.075, 0.82, 0.165, 1);
    transform-origin: bottom right;
  }
}

@keyframes shake {
  0%,
  100% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(-5deg);
  }
  75% {
    transform: rotate(5deg);
  }
}
