@import 'src/styles/basics';

.checkbox {
  display: flex;
  align-items: center;
}

.checkLabel {
  margin-left: var(--margin-1);
  font-weight: 500;
  line-height: calc(var(--font-size-regular) * 0.6);
}

.notifyWhen {
  margin-top: var(--margin-2);
}

.workspaceSelector {
  margin-left: auto;
  color: var(--primary-main6);
  font-weight: 700;
  align-self: center;
  cursor: pointer;
}
