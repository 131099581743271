@import 'src/styles/basics';

.createItem {
  height: 45px;
  width: 45px;
  background: $addSectionButtonBackground;
  border: 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 300ms all ease-in-out;

  &:hover {
    opacity: 0.9;
  }

  &:active {
    transform: scale(0.95);
  }

  &:disabled {
    opacity: 0.4;
  }
}

.positionAbsolute {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
}

.icon {
  height: 20px;
  width: 20px;
}
