@import 'src/styles/basics';

.contentContainer {
  min-height: 60vh;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: unset;
    display: block;
    font-size: unset;
    line-height: unset;
    text-align: unset;
    margin-top: 0.5rem !important;
    margin-block-end: 0.875rem !important;
  }

  h1 {
    font-size: 24px;
    font-weight: 700 !important;
    line-height: 25.92px;
  }
  h2 {
    font-size: 22px;
    font-weight: 800;
    line-height: 23.1px;
  }
  h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
  }
  h4 {
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
  }
  h5 {
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
  }
  h6 {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
  }

  pre {
    font-family: monospace;
    white-space: pre;
    margin: 1em 0px;
  }

  p,
  ul,
  ol,
  li,
  a {
    font-size: 1rem !important;
    font-weight: 400;
    line-height: 22px;
    margin-block-end: 0.875rem !important;
    white-space: normal !important;
  }
  a {
    font-weight: 600;
    color: #2385f8;
  }
  ol {
    display: block;
    list-style-type: revert;
    padding-inline-start: 40px;
  }
  ul {
    display: block;
    list-style-type: disc;
    padding-inline-start: 40px;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    max-width: 100%;
    margin-block-end: 1em;
    display: block;
    table-layout: auto;
    text-align: left;
    overflow: auto;
    background:
        /* Shadow covers */ linear-gradient(
        to right,
        white 30%,
        rgba(255, 255, 255, 0)
      ),
      linear-gradient(to right, rgba(255, 255, 255, 0), white 70%) 100% 0,
      /* Shadows */ radial-gradient(farthest-side at 0 50%, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)),
      radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)) 100% 0;
    background:
        /* Shadow covers */ linear-gradient(
        to right,
        white 30%,
        rgba(255, 255, 255, 0)
      ),
      linear-gradient(to right, rgba(255, 255, 255, 0), white 70%) 100% 0,
      /* Shadows */ radial-gradient(farthest-side at 0 50%, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)),
      radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)) 100% 0;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 60px 100%, 60px 100%, 28px 100%, 28px 100%;

    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;

    p {
      margin-block-end: 0.5em;
      word-wrap: break-word;
      font-size: 1rem !important;
    }
  }

  th,
  tr:first-of-type {
    background-color: #2b5889;
    border: 1px solid #ffffff47;
    color: #fff !important;
    font-weight: 700;

    p {
      color: #fff !important;
      font-weight: 700;
      font-size: 1rem !important;
    }
  }

  td,
  th {
    vertical-align: top;
    padding: 6px;
  }

  td {
    border: 1px solid #d3d3d3;
    height: 22px;
  }

  //offset for table of contents scrollIntoView
  h1,
  h2,
  h3 {
    &:first-of-type {
      padding-top: 0;
      margin-top: 0;
    }
  }

  img {
    object-fit: contain;
    max-width: 100%;
    max-height: 64rem !important;
  }
}
