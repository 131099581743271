@import 'src/styles/basics';
.root {
  flex: 1;
  margin: var(--margin-4);
}

.header {
  display: flex;
  gap: var(--margin-1);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  height: 5rem;
}

.popup {
  padding: var(--margin-4) var(--margin-3) var(--margin-2) var(--margin-3);
  color: var(--primary-main5);
}

.actions {
  margin-top: var(--margin-2);
}

.title {
  padding: 0;
  margin: 0;
}

.column {
  flex: 1;
}

.inputLabel {
  display: block;
  color: var(--black);
  margin-bottom: var(--margin-1);
}

.bold {
  font-weight: 600;
}

.tagContainer {
  display: flex;
  flex-wrap: wrap;
  gap: var(--margin-1);
}

.linkWrapper {
  display: flex;
  align-items: center;
  padding: 0 var(--margin-2);
  gap: var(--margin-3);
}

.newLinkButton {
  flex-grow: 0;
  margin-right: var(--margin-2);
}

.plusIcon {
  color: white;
}

.actions {
  display: flex;
  gap: var(--margin-1);
}
