@import 'src/styles/basics';

.buttonsContainer {
  display: flex;
  flex-direction: column;

  button {
    flex: 1;
    width: auto !important;

    &:not(:first-child) {
      margin-top: 16px;
    }
  }
}

.error {
  color: $error;
}
