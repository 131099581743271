@import 'src/styles/basics';

.root {
  display: flex;
  flex-direction: row;
  position: relative;

  border: 1px solid;
  border-radius: 0.5em;

  margin-bottom: 0.6rem;

  .textWrapper {
    display: flex;
    flex-direction: column;

    flex: 1 0 0;
    padding: 0.5rem 0.75rem;
    border-right: 1px solid;

    .title {
      font-weight: 700;
    }

    p {
      font-size: 0.75rem;
    }
  }

  .logoWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border-left-width: 1px;
    border-top-right-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    min-width: 5rem;
    padding: 0.3rem 0.875rem;

    .orgLogo {
      height: 5.695vmin;
      width: auto;
      object-fit: contain;
    }
  }

  .closeIcon {
    cursor: pointer;
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
    background-color: white;
    padding: 0.1rem;
    border-radius: 50%;

    :hover {
      opacity: 0.8;
    }
  }
}
