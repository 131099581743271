.root {
  display: flex;
  flex-direction: column;
  gap: 1.82rem;
}

.workspaceContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 0.88rem;
}

.box {
  flex: 0 0 180px;
  padding-top: 1rem;
  padding-right: 0.88rem;
  transition: all 200ms ease-in-out;
  position: relative;
  cursor: pointer;

  &.inActive {
    opacity: 0.5 !important;
  }

  &:hover,
  &.active {
    transform: translateY(-5px);
  }

  & > * {
    pointer-events: none;
  }
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
}
