@import 'src/styles/basics';

.root {
  height: var(--header-height);
  background: var(--neutral6);
  display: grid;
  grid-template-columns: 30% 1fr 50% 1fr 10%;
  align-items: center;
  z-index: 10;
  position: fixed;
  width: 100%;
  padding: 0 0 0 0.75rem;
}

.dropdowns {
  grid-column: 1 / 2;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.searches {
  grid-column: 3 / 4;
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.profileAndSettings {
  grid-column: 5 / 6;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}

.adminMode {
  display: flex;
  align-items: center;
  margin: 4px 0;
}

.toggleAdmin {
  margin-left: auto !important;
}

.avatar {
  border: none;
  background: none;
}

.avatarTooltip {
  padding: 0;
}

.settingsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: var(--header-height);
  width: var(--header-height);

  &:hover {
    background: rgba(255, 255, 255, 0.04);
  }
}

.workspacesDropdown {
  max-width: 12rem;
  align-content: center;
  height: min-content;
}

.workspaceDropdownWrapper {
  padding: 0.75rem;
}

.selectedWorkspaceName {
  font-family: 'Inter', sans-serif;
  font-size: 0.875rem;
  color: var(--neutral1);

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
