.fields {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  padding: 1rem 0;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
