@import 'src/styles/basics';
@import 'reset';
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;1,400;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;1,400;1,800;1,900&display=swap');

// @import 'overrides';

#root {
  height: 0;
  min-height: 100vh;
}

:root {
  // Primary colors
  --primary: #476ffd;
  --primary-snowy: #eef3ff;
  --primary-light: #e0e9ff;
  --primary-soft: #bdd4ff;
  --primary-secondary: #85a7ff;
  --primary-dark: #0c1934;

  --primaryMain9: #0d142d;

  --primary-text-negative: #eef3ff;
  --primary-text-positive: #0c1934;

  --primary-link-cta: #f37321;

  // General colors
  --gray-medium-light: #e1e1e1;
  --gray-light: #f0f0f0;
  --gray-dark: #5d5d5d;

  // Red colors
  --red-medium: #d6473e;

  // Blue colors
  --blue-medium: #0a7aff;

  // Font sizes
  --font-size-small: 0.5rem;
  --font-size-regular: 0.875rem;
  --font-size-medium: 1rem;
  --font-size-h1: 1.75rem;
  --font-size-h2: 1.375rem;
  --font-size-h3: 1.125rem;
  --font-size-label: 0.75rem;

  // Margins
  --margin-05: 0.25rem;
  --margin-1: 0.5rem;
  --margin-2: 1rem;
  --margin-3: 1.5rem;
  --margin-4: 2rem;
  --margin-5: 2.5rem;

  // Radius
  --button-radius: 0.25rem;
  --box-radius: 0.5rem;

  // Header
  --header-height: 4.5rem;
}

html {
  font-family: $font-raleway;
  font-variant-numeric: lining-nums proportional-nums;
  background: $pageBackground;
}

body {
  margin: 0;
  padding: 0;
  font-family: $font-raleway !important;
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.5;
  font-size: 14px;
  background: $pageBackground;
  color: $fontColor;
  -webkit-font-smoothing: antialiased;
}

input,
button,
textarea {
  font-family: $font-raleway;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

h1 {
  font-size: 35px;
  font-weight: 800;
  line-height: 2.09;
}

h2 {
  font-size: 24px;
  font-weight: 800;
}

h3 {
  font-size: 18px;
  font-weight: 700;
}

h4 {
  font-size: 14px;
  font-weight: 700;
}

a {
  text-decoration: none;
  color: var(--primary-main5);
}

.new-design-system {
  p,
  div,
  input {
    color: var(--primary-text-positive);
    font-size: var(--font-size-regular);
    line-height: 150%;
    font-family: 'Inter', sans-serif !important;
    white-space: pre-line;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  span {
    gap: var(--margin-1);
    font-family: 'Inter', sans-serif;
  }

  h1 {
    font-size: var(--font-size-h1);
    font-weight: 500;
    line-height: calc(var(--font-size-h1) * 1.1);
    color: var(--primary-text-positive);
    white-space: pre-line;
  }

  h2 {
    font-size: var(--font-size-h2);
    font-weight: 700;
    white-space: pre-line;
  }

  h3 {
    font-size: var(--font-size-h3);
    font-weight: 600;
  }

  h4 {
    font-size: var(--font-size-regular);
    font-weight: 700;
  }

  a {
    text-decoration: none;
    color: var(--primary);
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 0.7;
    }
  }

  .comprehensive-text {
    p,
    span,
    a,
    li {
      font-size: 1rem;
      line-height: 150%;
    }
  }

  .ant-input,
  .ant-select-dropdown {
    font-family: 'Inter', sans-serif !important;
  }
}
