@import 'src/styles/basics';

.sidebar {
  width: 5rem;
  background: var(--primary-main1);
  border-right: 1px solid var(--neutral4);
  box-shadow: 6px 0px 9px rgb(76, 86, 117, 0.04);
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: fixed;
  top: var(--header-height);
  bottom: 0;
  left: 0;
}

.menuRoot {
  border-bottom: 1px solid var(--neutral4);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: var(--font-size-small);
  line-height: 130%;
  letter-spacing: -1%;
  color: var(--neutral6) !important;
  font-weight: 500;
  padding: 0.85rem 0.25rem;
  transition: all 300ms ease-in-out !important;
  text-align: center;

  &:hover {
    box-shadow: 0px -1px 14px 0px #cee3f2 inset;
    background: var(--neutral1);
    opacity: 1 !important;
  }

  &.active {
    background: var(--neutral6-op10);
    color: var(--primary-main9) !important;
    box-shadow: 0px 0px 20px 0px rgb(76, 86, 117, 0.4) inset;
  }

  .icon {
    height: 2rem;
    width: 2rem;
    object-position: center;
    margin-bottom: 0.25rem;
  }
}

.subMenu {
  display: flex;
  flex-direction: column;
  max-height: 0vh;
  overflow: auto;
  transition: max-height 300ms ease-in-out;
  padding-left: 60px;
  padding-right: 15px;

  &.subMenuOpen {
    max-height: 80vh !important;
  }
}

.subMenuItem {
  margin: 5px 0;
  color: $darkSlateBlue !important;
  transition: color 300ms ease-in-out;
  font-size: 13px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:before {
    content: ' ';
    display: block;
    padding: 3px;
    margin-right: 10px;
    border-radius: 6px;
    background: $darkSlateBlue;
    transition: color 300ms ease-in-out;
  }

  &:hover {
    color: var(--primary-main5) !important;

    &:before {
      background: var(--primary-main5);
    }
  }
}
