.avatarImg {
  overflow: hidden;
  position: relative;
  flex-shrink: 0;
  margin-right: var(--margin-2);
}

.avatar {
  height: 3.75rem;
  width: 3.75rem;
  aspect-ratio: 1;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  &.small {
    height: 2.5rem;
    width: 2.5rem;

    .avatarName {
      font-size: 1rem;
    }
  }

  &.withHover {
    transition: all 0.3s ease-in-out;
  }

  &.withHover:hover {
    box-shadow: 0 0 0 3px var(--hover-shadow-color, var(--neutral5));
    background-color: var(--hover-bg-color, var(--neutral1)) !important;
  }

  &.medium {
    height: 3.75rem;
    width: 3.75rem;
  }
  &.large {
    height: 5rem;
    width: 5rem;
  }

  .image {
    height: 100%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    object-fit: contain;
    object-position: bottom;
  }

  .avatarName {
    font-weight: 700;
    font-size: 1.35rem;
  }
}
