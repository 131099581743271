@import 'src/styles/basics';

.container {
  border-radius: 16px;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  align-items: center;

  .logo {
    width: 200px;
  }

  .button {
    margin-top: 25px;
    width: 280px;
  }

  .footer {
    margin-top: 25px;
    text-align: center;
  }
}
