@import 'src/styles/basics';

.dropdown {
  width: fit-content;
  width: 230px;

  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  background: #ffffff;
}

.icon {
  margin-left: 10px;
  color: $brownGrey !important;
}

.icon:hover {
  cursor: pointer;
  color: $iconColor !important;
}

.option {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding-right: 5px;
  font-size: 13px;

  .optionIcon {
    font-size: 13px;
    color: $iconColor;
    padding: 0 5px;
  }
}
