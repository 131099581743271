@import 'src/styles/basics';

.title {
  font-size: $headerFontSize;
  text-align: center;
  margin-bottom: 30px;
  color: $primary;
}

.wrapper {
  border-radius: 10px;
  min-height: 100px;
  width: 100%;
  border: 1.5px dashed var(--primary-main5);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  .uploadBox {
    h4 {
      color: var(--primary-main5);
      font-size: 20px;
      line-height: 1.2;
    }

    p {
      margin: 5px 0;
      font-size: 14px;
    }
  }
}

.checkbox {
  display: flex;
  margin-top: 10px;
  justify-items: center;

  h6 {
    margin-left: 10px;
    align-self: center;
    font-size: 14px;
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  align-items: flex-end;

  .button {
    max-width: 48%;
  }
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $primary;

  h6 {
    font-size: 14px;
    text-align: center;
    white-space: pre-line;
  }
}

.error {
  text-align: center;
  color: $primary;
  margin-bottom: 10px;
}

.notFound {
  min-height: min-content;
}

.duplicationTitle {
  @extend .title;
  margin-bottom: 10px;
  text-align: left;
  align-self: flex-start;
}

.duplicationSubtitle {
  @extend .error;
  align-self: flex-start;
  text-align: left !important;
  margin: 0;
}
