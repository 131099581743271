@import 'src/styles/basics';

.deleteIcon {
  height: 25px;
  width: 25px;
  cursor: pointer;
  margin-right: 15px;
}

.changeOrderIcon {
  cursor: pointer;
  height: 24px;
  width: 24px;
  margin: 0 10px;
}

.changeOrderUp {
  transform: rotate(-90deg);
}

.changeOrderDown {
  transform: rotate(90deg);
}

.editIcon {
  height: 25px;
  width: 20px;
  cursor: pointer;
}
