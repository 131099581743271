@import 'src/styles/basics';

.root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 4px;
  width: 100%;
}

.separate:not(:last-child) {
  border-bottom: 1px solid $lightDarkGray;
  padding-bottom: 10px;
}

.expirationMessage {
  color: $redMedium;
}

.aboutToBeExpired {
  color: $black;
}

.aboutToBeExpired,
.expirationMessage {
  font-weight: 700;
}

.expirationButtons {
  font-size: 13px;
}

.extendTime {
  color: $colorPrimary;
  background: transparent;
  border: 0;
  padding: 0 10px;
  font-weight: 600;
  display: inline-block;
  position: relative;
  white-space: nowrap;

  &:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 20%;
    right: 0;
    width: 1.5px;
    background: $black;
    height: 80%;
    margin-left: 5px;
  }
}
