@import 'src/styles/basics';

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 604px;
  height: 383px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 30px;
}
.icon {
  margin-top: 40px;
  height: 104px;
  width: 82px;
}

.text {
  color: $white;
  margin: 30px 134px 0 134px;
  text-align: center;
  font-weight: 700;
  line-height: 34px;
}

.button {
  flex: none;
  width: fit-content;
  margin-top: 47px;
  margin-bottom: 52px;
}
