.root {
  display: flex;
  flex-direction: column;
}

.topBorder {
  margin-top: var(--margin-2);
  border-top: 1px solid var(--neutral5);
  padding-top: var(--margin-2);
}

.switch {
  display: flex;
  gap: 0.875rem;
  align-items: center;
  margin-top: 0.5rem;
}

.switchLabel {
  line-height: 120%;
  letter-spacing: -1%;
}

.titleLinkWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.link {
  text-decoration: underline;
}
