@import 'src/styles/basics';

.container {
  display: flex;
  gap: var(--margin-1);
  align-items: center;
  width: 100%;
  padding: var(--margin-1);
  cursor: pointer;
  border-radius: 8px;
  background-color: var(--white);
  min-height: 5rem;

  &:hover {
    background-color: var(--primary-main2);
    opacity: 0.8;
  }
}

.hide {
  display: none;
}

.selected {
  background: var(--primary-main2);
  border: 1px solid var(--primary-main4);
  &:hover {
    opacity: 1;
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: var(--margin-1);
}

.itemIcon {
  width: 45px;
  height: 45px;
  margin: 0 var(--margin-1);
  align-self: center;
}

.thumbnailWrapper {
  width: 80px;
  display: flex;
  align-self: stretch;
  margin-right: 10px;
  flex-grow: 0;
}

.thumbnail {
  object-position: center;
  object-fit: cover;
  align-self: stretch;
  height: 100%;
  width: 100%;
}

.item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.title {
  font-size: var(--font-size-medium) !important;
  font-weight: 600;
  text-overflow: ellipsis;
}

.labelsContainer {
  display: flex;
  justify-content: flex-end;
  align-self: start;
  flex-wrap: wrap;
  gap: var(--margin-1) 0;
}

.label {
  height: 1.4rem;
  font-size: var(--font-size-label) !important;
  font-weight: 500;
}

.itemContent {
  display: flex;
}

.disabled {
  opacity: 0.4;
  cursor: not-allowed;

  &:hover {
    opacity: 0.4;
    background-color: var(--white);
  }
}

.footer {
  flex-wrap: wrap;
  align-items: center;

  span {
    overflow-x: hidden;
    font-size: var(--font-size-label) !important;
    &:not(.divider) {
      margin-right: var(--margin-05);
    }

    &.divider {
      margin: 0 var(--margin-1);
    }
  }
}

.categoryIcon {
  width: 40px;
  height: 40px;
  margin: 0 var(--margin-1);
  align-self: center;
}
