@import 'src/styles/basics';

.iframe {
  margin-top: 10px;
  width: 100%;
  min-height: 80vh;
}

.hideIframe {
  visibility: hidden;
  position: absolute;
  z-index: -1;
}

.notAvailableLink {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.externalIcon {
  height: 70px;
  margin-bottom: 24px;
}

.externalHeader {
  font-size: 20px;
  font-weight: 700;
  line-height: 34px;
  white-space: break-spaces;
  text-align: center;
}

.externalSubheader {
  text-align: center;
}

.openExternal {
  position: sticky;
  bottom: 0;
  right: 0;
  left: 0;
  flex: 0 0 42px;
  align-items: center;
  justify-content: flex-end;
  background: white;
  border: 1px dashed $lightGray3;
  margin-top: 4px;
  padding: 0 8px;
  display: flex;
  font-size: 13px;
  cursor: pointer;
}

.openExternalIcon {
  height: 20px;
  margin-left: 8px;
}

.loaderWrapper {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.button {
  overflow-wrap: anywhere;
  height: auto;
}
