@import 'src/styles/basics';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 20vh;
}

.header {
  color: var(--primary-main6);
  margin-bottom: 20px;
}
