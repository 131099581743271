.root {
  display: flex;
  flex-direction: column;
}

.workspaceName {
  color: var(--primary-main9);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.copyHeaderWrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.icon {
  font-size: 20px;
}
