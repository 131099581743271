@import 'src/styles/basics';

.data {
  flex-wrap: wrap;
}

.detail {
  margin: 10px 0;
  word-break: break-all;
  white-space: pre-line;
}

.contentExpiryDateGood {
  color: $contentExpiryDateGood;
}

.contentExpired {
  color: $contentExpired;
}

.exclamationMark {
  height: 15px;
  width: 15px;
  margin-left: 5px;
}

.expiryDate {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.breadcrumbs {
  margin-top: 0 !important;
  margin-bottom: 10px;
}

.tags {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -20px;
}
.tagIcons {
  height: 45px;
  width: 45px;
}

.title {
  font-weight: 500;
}
