@import 'src/styles/basics';

.box {
  flex: 0 0 48%;
  margin-top: 23px;
  margin-right: 23px;
  transition: all 200ms ease-in-out;
  position: relative;

  &:hover,
  &.active {
  }
}

.boxWrapper {
  display: flex;
  overflow: hidden;
  border-radius: 15px;
  background: $lightGray;
  flex-direction: column;
  height: 200px;

  &.boxWrapperContent {
    box-shadow: 4px 4px 4px -3px rgba(0, 0, 0, 0.15);

    &:hover,
    .active & {
      box-shadow: 4px 4px 4px -3px rgba(0, 0, 0, 0.15);
    }
  }
}

.image {
  width: 100%;
  height: 130px;
  border: 0;
  padding: 10px;
  background: var(--neutral7);

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
  }
}

.boxHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 7px;
  line-height: 1.29;
  font-size: 12px;
  flex: 1;
  border: 0;
  text-align: left;
  background: transparent;
  cursor: pointer;
  color: $boxContentTextColor;
  font-size: 14px;
  line-height: 15px;

  .arrow {
    display: block;
    height: 15px;
    width: 20px;
    padding-left: 5px;
    flex: 0 0 20px;
    margin-left: auto;
  }
}
