@import 'src/styles/basics';

.content {
  text-align: center;
}

.checkbox {
  margin: 0.5rem 0;
}

.label {
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.0075rem;
  padding-bottom: var(--margin-1);
  color: var(--neutral6);
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}

.closeBtn {
  align-self: flex-end;
  max-width: min-content;
}

.categorySelect {
  margin-right: 15px;
}

.categoriesTypes {
  margin-bottom: 15px;
  text-align: center;
  display: flex;
  flex-direction: row;
}

.success {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  p {
    a {
      font-weight: 700;
    }
  }
}
