@import 'src/styles/basics';

.root {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 10vw;
  text-align: center;
}

.image {
  height: 35vh;
  margin-bottom: 5vh;
}

.header {
  font-size: 24px !important;
  font-weight: 700 !important;
  line-height: 1.5;
  color: var(--primary-main5) !important;
  margin-bottom: 2vh;
}

.subHeader {
  font-size: 16px !important;
  font-weight: 500 !important;
  margin-bottom: 2vh;
}

.download {
  margin-top: 7vh;
  display: flex;
}

.downloadButton {
  width: 40vw;
  max-width: 200px;
  margin: 0 10px;
}
