@import 'src/styles/basics';

.root {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 15px 0;
}

.wrapper {
  background: $inputEditorBackground;
  border-radius: 4px;
  padding: 0 15px;
}

.error {
  text-align: left;
  margin-top: 4px;
  color: $inputErrorColor;
}

.editorControls {
  padding: 8px 0;
  border-bottom: 1px solid $inputEditorPlaceholder;
}

.styleButton {
  margin-right: 10px;
  color: $lightGray3;
  cursor: pointer;
}

.styleButtonActive {
  color: var(--primary-main5);
}

.editor {
  margin: 8px 0;
  font-size: 14px;
  color: $primary;

  div[data-block] {
    margin-bottom: 10px;
  }
}

.inline {
  font-size: $itemTitleFontSize;
  font-weight: $itemTitleFontWeight !important;
  color: var(--neutral6);
  justify-content: flex-start;
  white-space: pre-line;
  height: fit-content;
  background: transparent !important;
  border-color: transparent !important;
  &:disabled {
    color: var(--neutral6) !important;
    background: transparent !important;
    cursor: pointer;
    pointer-events: none;
    -webkit-text-fill-color: var(--neutral6); /* Override iOS / Android font color change */
    -webkit-opacity: 1; /* Override iOS opacity change affecting text & background color */
  }

  &:focus,
  .admin &:hover {
    border-color: rgba($black, 0.1) !important;
    background: transparent !important;
  }
}
