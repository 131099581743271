@import 'src/styles/basics';

.popup {
  z-index: 99999;
}

.buttons {
  display: flex;
  margin-top: 15px;
}

.button {
  margin-right: 5px;
  margin-left: 5px;
}

.error {
  color: $inputErrorColor;
  margin-left: 5px;
  margin-top: 5px;
}
