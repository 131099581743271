.buttons {
  display: flex;
  margin: 1.6rem 0 0.7rem 0;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;

  & > button {
    width: 100%;
  }
}
