@import 'src/styles/basics';

.content {
  font-weight: 600;
}

.smartFormatToggle {
  display: flex;
  align-items: center;
  margin-bottom: calc(-1 * var(--margin-2));
}
