@import 'src/styles/basics';

.rootForm {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.validationPart {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--margin-1);
}

.smallWrapper {
  min-height: 32px;
  min-width: 150px;
  background-color: $white;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  font-weight: 800;
}

.inlineInput {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: var(--margin-2) var(--margin-1);
  border-radius: 4px;
  height: 2rem;
  border: 1px solid var(--neutral4);

  > label {
    white-space: nowrap;
    color: var(--neutral5);
    font-weight: 500;
    margin-right: var(--margin-1);
  }
}

.tagsWrapper {
  min-height: $input-height;
  padding: 0;
  background-color: $white;
  border: 0.5px solid $veryLightGray;
  border-radius: $input-radius;
}

.content {
  min-height: 100vh;
}

.header {
  display: flex;
  align-items: center;
  gap: var(--margin-1);
}

.back {
  margin-bottom: var(--margin-1);
}

.error {
  color: var(--red-medium);
}

.rightPanelWrapper {
  padding: var(--margin-2) 0;
}

.notificationMessage {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 0.4rem;

  .bold {
    font-weight: 700;
  }
}
