@import 'src/styles/basics';

.root {
  padding: $page-padding;
}

.header {
  font-weight: 300;
  font-size: 20px;
  color: $contentItemTitle;
}

.organizations {
  display: flex;
  flex-direction: row;
}
