@import 'src/styles/basics';

.root {
  width: 100%;
  min-height: 70px;
  background: $knowledgeItemBackground;
  border-radius: 15px;
  margin: 15px 0 0;
  display: flex;
  overflow: hidden;
  align-items: center;
  cursor: pointer;

  &.light {
    background: $knowledgeItemBackgroundLight;
  }

  &.preventOpen {
    cursor: default;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 8px 5px 8px 10px;
  flex: 1;

  h4 {
    font-size: var(--font-size-regular);
    font-weight: 700;
    color: var(--primary-main8);
  }

  .subtitle {
    color: var(--neutral6);
    font-size: var(--font-size-regular);
  }

  .titleTextarea {
    font-size: $itemTitleFontSize !important;
    font-weight: $itemTitleFontWeight !important;
    line-height: 1.5;
    color: $knowledgeItemTitle;
    font-weight: normal;
    padding: 0px;
    background: transparent !important;
    border-color: transparent !important;

    &:disabled {
      color: $knowledgeItemTitle !important;
      background: transparent !important;
      cursor: pointer;
      pointer-events: none;
    }

    &:focus,
    .admin &:hover {
      border-color: rgba($black, 0.1) !important;
      background: transparent !important;
    }
  }

  .subtitleTextarea {
    font-size: $itemTitleFontSize !important;
    font-weight: $itemTitleFontWeight !important;
    padding: 0px;
    margin-top: -3px;
    color: var(--neutral6);
    background: transparent !important;
    border-color: transparent !important;

    &:disabled {
      color: var(--neutral6) !important;
      background: transparent !important;
    }

    &:focus,
    .admin &:hover {
      border-color: rgba($black, 0.1) !important;
      background: transparent !important;
    }
  }
}

.thumbnailWrapper {
  width: 80px;
  display: flex;
  align-self: stretch;
  margin-right: 10px;
}

.thumbnail {
  object-position: center;
  object-fit: cover;
  align-self: stretch;
  height: 100%;
  width: 100%;
}

.buttons {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  padding-left: 15px;
  justify-content: center;
  align-items: center;
  margin-right: 7px;
}

.buttonWrapper {
  height: 30px;
  width: 30px;
  background: $knowledgeItemIconWrapperWeb;
  border: 0;
  margin-right: 7px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 300ms ease-in-out;

  .light & {
    background: $knowledgeItemIconWrapper;
  }

  &:hover,
  &:disabled {
    background: $knowledgeItemIconWrapperWebHover;
  }
  .actionIcon {
    color: $primary;

    &:disabled {
      color: $lightGray;
      cursor: default;
    }
  }
}

.informationIcon {
  height: 13px;
  width: 13px;
}

.unselectIcon {
  height: 12px;
  width: 12px;
  padding-left: 1px;
}

.deleteIcon {
  height: 16px;
  width: 16px;
}

.favoritesIcon {
  height: 25px;
  width: 25px;
}

.dragIconWrapper {
  cursor: move;
}

.dragIcon {
  height: 14px;
  width: 24px;
}

.editIcon {
  height: 14px;
  width: 24px;
}

.changeDirectory {
  height: 14px;
  width: 24px;
}

.linkIcon {
  height: 15px;
  width: 15px;
}

.itemIcon {
  width: 45px;
  height: 45px;
  margin: 0 0px 0 15px;
  padding: 0.7px 0px 4.8px 0;
}
