@import 'src/styles/basics';

.root {
  padding: $page-padding;
}

.header {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  h2 {
    flex: 1;
    font-weight: $headerFontWeight;
    font-size: 24px;
    line-height: 28px;
    color: var(--primary-main8);
    margin-bottom: 12px;
  }
  h3 {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
  }
}

.inputWrapper {
  margin: 0;
}

.form {
  margin-top: 37px;
}

.recipients {
  margin-bottom: 16px;
}

.recipientsSelectorHeader {
  display: flex;
}

.workspaceSelector {
  margin-left: auto;
  color: var(--primary-main6);
  font-weight: 700;
  align-self: center;
  cursor: pointer;
}

.multiInput {
  background-color: $white;
  border: 1px solid $grayLight1;
  border-radius: $input-radius;
  padding: 0 5px 25px 5px;

  input {
    height: 40px;
  }
  textarea {
    resize: none;
    padding-top: 8px;
  }
  .line {
    border-bottom: 1.5px solid #d3d3d3;
    margin: 0 10px 0 10px;
  }
}

.inputUrl {
  border: 1px solid $grayLight1 !important;
  border-radius: $input-radius;
  height: 40px !important;
  margin-top: 12px !important;
}

.dismissButton {
  margin: 0 auto;
  max-width: 300px;
  width: 100%;
}

.doctorWrapper {
  flex: 1;
  text-align: center;
  margin-bottom: 20px;
}

.doctor {
  width: 170px;
}

.describer {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  margin: 10px 0;
  color: $grayLight;
}

.urlMessage {
  margin: 10px 0 0 10px;
}

.toggleContainer {
  margin: 60px 0px 50px 0px;
}

.toggleWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.checkLabel {
  margin-left: 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: rgba(113, 113, 113, 1);
}

.button {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  height: 48px;
  padding: 8px 14px;
  min-width: 170px;
  border-radius: 5px;
}

.error {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #d6473e;
}

.errorIcon {
  width: 20px;
  height: 20px;
  color: #d6473e;
  margin-right: 13px;
}
