@import 'src/styles/basics';

.root {
  margin: var(--margin-3);
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: var(--margin-4);
}

.marginBox {
  margin-top: -60px;
}

.link {
  display: inline;
}
