@import 'src/styles/basics';

.progressWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .title {
    font-size: $headerFontSize;
    text-align: center;
    margin-bottom: 20px;
    color: $primary;
  }

  .fileWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-left: 20px;

    img {
      z-index: 10 !important;
      margin-bottom: -15px;
      height: 65px;
      width: 65px;
    }

    p {
      font-size: 14px;
      font-weight: 700;
    }
  }

  .progressBar {
    width: 100%;
    height: 27px;
    background-color: #ddd;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 25px;

    .progressBarFill {
      height: 100%;
      background-color: var(--primary-main5);
      border-radius: 30px;
      transition: width 0.3s ease;
    }
  }

  .popup {
    width: 36%;

    .button {
      margin: 10px;
    }

    .buttonCancel {
      margin: 10px;
      color: #fff;
      background-color: #d6473e;
      border: 1px solid #d6473e;

      &:hover {
        color: #d6473e;
        background-color: #fff;
      }
    }
  }

  .buttons {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: space-around;

    .button {
      border-radius: 10px;
      font-size: 15px;
      height: 40px;
    }
  }
}

.simpleProgressBar {
  width: 100%;
  height: 0.67rem;
  background-color: #ddd;
  border-radius: 1.25rem;
  overflow: hidden;

  .progressBarFill {
    height: 100%;
    background-color: var(--primary-main5);
    border-radius: 1.25rem;
    transition: width 0.3s ease;
  }

  .progressBarFillComplete {
    background-color: var(--green-main, #00bf2a);
  }
}
