.root {
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.fields {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.sectionTitle {
  color: var(--primary-main9);
  font-size: 1rem;
  font-weight: 700;
}

.sectionHeader {
  display: flex;
  align-items: center;
  gap: 0.625rem;
}

.scheduleUrlsList {
  padding: 1rem 0 0.5rem 0;
}

.quickLinksList {
  padding: 1rem 0;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 1rem 4rem;
  flex-wrap: wrap;
}

.modalTitle {
  color: var(--blue-dark);
}

.label {
  font-size: 0.75rem;
  color: var(--neutral6);
  padding: 0 !important;
}

.dashboardBannerContent {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.imageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
}

.imageLink {
  font-weight: 700;
  color: var(--primary-main6);
}

.previewText {
  color: var(--primary-main9);
  font-size: 1rem;
  font-weight: 700;
}
