@import 'src/styles/basics';

.container {
  display: flex;
  height: 250px;
  align-items: center;
  position: relative;

  &.oauthFooter {
    height: 43vh;

    .panda {
      top: 38%;
    }
  }

  .panda {
    position: absolute;
    left: 30px;
    top: 50px;
    width: 30%;
  }

  .content {
    position: absolute;
    right: 30px;
    display: flex;
    flex-direction: column;

    .title {
      font-size: 32px;
    }

    .description {
      margin-bottom: 10px;
      white-space: pre-wrap;
      width: 380px;
      line-height: 24px;
    }

    .input {
      margin-bottom: 5px;
      width: 380px;
    }
  }
  .error {
    margin-top: 10px;
    color: $error;
    max-width: 380px;
  }
}

.success {
  display: flex;

  .successContent {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-left: 20px;

    .header {
      display: flex;
      align-items: center;

      .iconContainer {
        background-color: #21a45c;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        align-content: center;
        text-align: center;
        border: 3px solid #1b864b;

        .icon {
          color: white;
          font-size: xx-large;
        }
      }

      .headerTitle {
        margin-left: 20px;
        text-transform: uppercase;
        font-size: 26px;
        font-weight: 700;
      }
    }

    .description {
      margin-top: 20px;
      font-size: 18px;
    }
  }

  .image {
    position: absolute;
    right: 10px;
    width: 50%;
  }
}

.marginBottom {
  margin-bottom: 1rem;
}
