@import 'src/styles/basics';

.results {
  display: flex;
  flex-direction: column;
  gap: var(--margin-1);
  align-items: center;
  overflow-y: scroll;
  height: 20rem;
  margin-top: var(--margin-2);
}

.buttons {
  display: flex;
  gap: var(--margin-1);
  justify-content: space-between;
}

.notFound {
  min-height: 0;
}

.clearSelected {
  margin-top: var(--margin-1);
  align-self: flex-start;
}
