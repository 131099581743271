.root {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  margin: 16px 18px 0 18px;
}

.buttonWrapper {
  margin: 0;
  padding: 0;
  background: transparent;
  border: 0;
  transition: 300ms opacity ease-in-out;
  margin-right: 17px;

  &:hover {
    opacity: 0.7;
  }
}

.backArrow {
  width: 22px;
  height: 19px;
}

.title {
  font-size: 24px;
  font-weight: 800;
  line-height: 26px;
  margin: 0px;
}

.viewOriginal {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--margin-05);
}

.logsTable {
  table-layout: fixed;
  border-collapse: collapse;
  margin: 16px;

  thead {
    background-color: #2b5889;
    color: #fff;
    height: 35px;
    td {
      font-size: 16px;
      font-weight: 700;
      line-height: 19px;
      padding: 8px;
      border: none;
    }

    tr {
      border: none;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #d3d3d3;
      height: 22px;
      vertical-align: top;
    }

    td {
      padding: 8px;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
    }
  }
}
