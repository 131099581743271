.root {
  position: fixed;
  top: var(--header-height);
  bottom: 0;
  min-width: 500px;
  width: 40vw;
  background: white;
  right: -40vw;
  z-index: 9;
  box-shadow: -5px 10px 30px 0 rgba(0, 0, 0, 0.2);
  transition: all 500ms ease-in-out;
  overflow: scroll;
  padding: 34px;
  transform: translateX(45vw);

  &.open {
    transform: translateX(-40vw);
  }
}

.close {
  position: absolute;
  right: 50px;
  top: 15px;
  cursor: pointer;
}
