@import 'src/styles/basics';

.root {
  padding: $page-padding;
}

.mainContent {
  padding: var(--margin-2) 0;
  margin: var(--margin-2) 0;
  border-top: 1px solid var(--neutral5);
  border-bottom: 1px solid var(--neutral5);
}

.content {
  margin-top: var(--margin-1);
}

.redirect {
  color: var(--primary-main6);
  font-size: 1.125rem;
  font-weight: 600;
  text-decoration: underline !important;
}
