@import 'src/styles/basics';

.buttonsContainer {
  margin-top: var(--margin-2);
  display: flex;
  gap: var(--margin-1);

  button {
    flex: 1;
  }
}

.error {
  color: $error;
}

.link {
  font-weight: 500;

  p {
    display: inline;
    color: $iconColor;
    text-decoration: underline;
    cursor: pointer;
  }
}

.form {
  display: flex;
  flex-direction: column;

  .note {
    display: flex;
    padding: 1px 8px;
    align-items: center;
    justify-content: center;
    gap: 4px;
    align-self: stretch;
    margin-bottom: 14px;
    border-radius: 4px;
    text-wrap: wrap;
    width: 100%;
  }

  .emailWrapper {
    display: flex;
    flex-direction: column;
    gap: 6px;

    label {
      color: --var(--neutral6);
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      letter-spacing: -0.0075rem;
    }
  }

  .emailInput {
    min-height: 48px;
    align-items: center;
    overflow: hidden;
    border-radius: 4px;
    padding: 0;
    background: $inputEditorBackground;
    background-color: $white;
    border: 1px solid #f0f0f0;

    font-weight: 700 !important;
  }
}

.fields {
  display: flex;
  flex-direction: column;
  gap: var(--margin-05);
}

.emailDropdown {
  display: none;
}
