@import 'src/styles/basics';

.anchor {
  display: block;
  height: 5px;
  width: 5px;
  position: relative;
  top: -80px;
  z-index: -1;
}

.header {
  display: flex;
  align-items: center;
  position: relative;
}

.headerText {
  font-weight: $headerFontWeight !important;
  font-size: $headerFontSize !important;
  color: var(--primary-main8) !important;
  padding: 5px !important;
  margin-left: -5px !important;
  background: transparent !important;
  border-color: transparent !important;

  &:disabled {
    color: var(--primary-main8) !important;
    background: transparent !important;
    -webkit-text-fill-color: var(--primary-main8); /* Override iOS / Android font color change */
    -webkit-opacity: 1; /* Override iOS opacity change affecting text & background color */
  }

  &:focus,
  .admin &:hover {
    border-color: rgba($black, 0.1) !important;
    background: transparent !important;
  }
}

.adminActions {
  display: none;
  transition: 300ms ease-in-out opacity;

  .admin & {
    display: flex;
    opacity: 0;
    margin-left: 15px;
  }

  .admin:hover & {
    opacity: 1;
  }
}

.items {
  margin-top: var(--margin-2);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  transition: max-height 400ms ease-in-out;
  gap: 0.88rem;
}

.itemsColumn {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
}
