.popup {
  max-height: 44rem;

  :global {
    .ant-modal-content {
      background: var(--primary-main1);
      padding: 0;
      border-radius: 0;
    }
  }
}
