@import 'src/styles/basics';

.tagContainer {
  display: flex;
  min-height: 30px;
}

.tag {
  height: 35px;
  width: 35px;
  padding: 1px;
  margin: 6px 2px 2px 2px;
  border-radius: 20%;
  background-color: $white;

  &.tagAudience {
    padding: 4px;
  }
}

.largeContainer {
  display: flex;
}

.largeTag {
  height: 55px;
  width: 55px;
  border-radius: 20%;
  padding: 2px;
  margin: 3px;
  background-color: $white;

  &.tagAudience {
    padding: 6px;
  }
}

.centerContainer {
  display: flex;
  justify-content: center;
}

.singleTag {
  border-radius: 20%;
  height: 30px;
  width: 30px;
  margin: 0px 8px 0px 0px;
  padding: 3px;
  cursor: default;

  &.darkBackground {
    background-color: $lightGray2;
  }
}
