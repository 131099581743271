.quickLink {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .iconUrl {
    width: 3.75rem;
    height: 3.75rem;
    align-self: center;
    justify-self: center;

    > img {
      border-radius: 0.6rem;
    }
  }

  .quickLinkOverlay {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .quickLinkOverlayWrapper {
    background-color: var(--primary-main6);
    min-width: 3.75rem;
    min-height: 3.75rem;
    display: flex;
    align-self: center;
    justify-self: center;
    border-radius: 0.6rem;

    .editQuickLink {
      align-self: center;
      justify-self: center;
      cursor: pointer;
    }

    .deleteQuickLink {
      position: absolute;
      top: 1.75rem;
      left: 1.75rem;
      background-color: var(--red);
      border-radius: 50%;
      padding: 0.25rem;
      border: solid 0.125rem var(--neutral1);
      cursor: pointer;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    }
  }

  span {
    margin-top: 1rem;
    text-align: center;
    max-width: 7.5rem;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.15px;
  }
}
