@import 'src/styles/basics';

.content {
  text-align: center;
  font-size: 16px;
}

.buttons {
  display: flex;
  margin-top: 15px;
}

.button {
  margin-right: 5px;
  margin-left: 5px;
}

.categorySelect {
  margin-right: 5px;
  margin-left: 5px;
  position: relative;
}

.categoriesTypes {
  margin-bottom: 15px;
  text-align: center;
  display: flex;
  flex-direction: row;
}

.itemId {
  font-size: 10px;
  color: $lightGray3;
  float: right;
  margin-right: 5px;
}

.icon {
  height: 25px;
  width: 25px;
  position: absolute;
  left: 15px;
}

.widgetToggle {
  margin-top: 16px;

  label {
    margin-left: 8px;
  }
}

:export {
  folderColor: $folderKnowledgeColor;
}
