.submitButtons {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  padding-bottom: 1rem;
}

.label {
  font-size: 0.75rem;
  color: var(--neutral6);
  padding: 0 !important;
}

.editing {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  min-width: max-content;
  max-width: 30%;
}

.previewText {
  color: var(--primary-main9);
  font-size: 1rem;
  font-weight: 700;
}

.preview {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.previewTextWrapper {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
