.item {
  display: flex;
  width: 100%;
  padding: var(--margin-1) var(--margin-1) 0 var(--margin-1);
  min-height: 2rem;
  max-width: 15rem;
  border: 0 solid transparent;
  border-bottom-width: 1px;
  border-bottom-color: var(--neutral4);
  background: none;
  cursor: pointer;

  &:hover {
    background-color: var(--primary-main2);
    cursor: pointer;
  }
}

.itemSelected {
  background-color: var(--primary-main2);
}

.itemTitle {
  display: flex;
  gap: var(--margin-1);
  color: var(--primary-main6) !important;
  align-items: center;
  text-align: left;
  margin-bottom: var(--margin-1);
}

.itemSelectedText {
  color: var(--blue-dark) !important;
  font-weight: 700;
}
