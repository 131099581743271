@import 'src/styles/basics';

.root {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 15px 0;
}

.input {
  border: none;
  min-height: 90px;
  max-height: 150px;
  font-size: 17px;
}

.user {
  border-bottom: 1px solid $inputBorderColor;
}

.user::placeholder {
  color: $inputPlaceholder;
}

.editor {
  background: $inputEditorBackground;
  color: $primary;
  font-size: 14px;
  border-radius: 4px;
  padding: 15px;
}

.editor::placeholder {
  color: $inputEditorPlaceholder;
}

.error {
  text-align: left;
  margin-top: 4px;
  color: $inputErrorColor;
}
