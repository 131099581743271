@import 'src/styles/basics';

.selector {
  width: 100%;
  margin: 5px 0px;
}

.option {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
