.root {
  display: flex;
  gap: 0.25rem;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;

  a:not(:last-child)::after {
    content: ',';
    color: var(--primary-main9);
    font-size: 0.875rem !important;
    font-weight: 400;
  }

  > a {
    margin: 0;
    color: var(--primary-main6);
    font-weight: 500;
  }
}
