.headerActionsButton {
  white-space: normal;
  height: auto;
}

.title {
  color: var(--Blue-Extra-dark, #243b54);
  font-size: 1.25rem !important;
  font-weight: 700 !important;
  line-height: 100%;
  letter-spacing: -0.0125rem;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.divider {
  margin: 0.5rem 0;
}

.workspace {
  display: flex;
  flex-direction: row;
  width: fit-content;
  flex: 1;

  p {
    color: var(--neutral5);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.01rem;
  }
}

.moveToRoot {
  align-content: flex-start;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.breadcrumbs {
  display: flex;
  flex-direction: row;
  color: var(--primary-main6);
  align-items: center;
  margin-bottom: 0.5rem;
  flex-wrap: wrap;
  gap: 0.5rem;

  .afterHomeBreadcrumbs {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .separator {
    color: var(--neutral6);
    font-size: 0.62rem;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.00625rem;
  }

  h3 {
    color: var(--primary-main6);
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.1rem;
    letter-spacing: -0.01rem;
    cursor: pointer;
  }
}

h2 {
  color: var(--primary-main8);
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -0.0125rem;
}

.categoriesContainer {
  display: flex;
  flex-direction: column;
  max-height: 25rem;
  overflow: scroll;
}

.row {
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.categoryRow {
  background-color: var(--neutral1);
}

.selected {
  background-color: var(--primary-main3);
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
