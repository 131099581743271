/*
  * renderIcon
  */
.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  padding: 0rem 0rem 0.2rem 0rem;
  flex-shrink: 0;
}

.knowledgeIcon {
  padding: 0;
}

.checklistIcon {
  padding: 0;
}

/*
  * renderTags
  */
.tags {
  display: flex;
  max-width: 100%;
  gap: 0.5rem;
}

.tag {
  display: flex;
  padding: 0.1875rem 0.5625rem 0.1875rem 0.625rem;
  align-items: center;
  border-radius: 3.75rem;
  border: 1px solid var(--neutral600);
  background: var(--neutral1);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  p {
    color: var(--neutral600) !important;
    font-size: 0.81rem !important;
    font-weight: 500;
    line-height: 0.89rem !important;
    letter-spacing: -0.01625rem;
    white-space: nowrap !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.contentType {
  display: flex;
  align-items: center;
  gap: 0.25rem;

  p {
    text-align: right;
    text-wrap: nowrap;
    color: var(--neutral5) !important;
    font-size: 0.93rem !important;
    font-weight: 400;
    line-height: 120% !important;
    letter-spacing: -0.00938rem;
  }
}

/*
* renderLabels
*/
.labelsContainer {
  height: 1.37rem;
  display: flex;
  gap: 0.5rem;
  margin-right: 0.5rem;
}

.label {
  margin-right: 0;
}

/*
* renderMetadata
*/
.metadata {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  gap: 0.5rem;
}

.metadataContainer {
  display: flex;
  padding-right: 0.75rem;
  align-items: center;
  gap: 0.25rem;
  border-right: 1px solid var(--neutral4);

  &:last-child {
    border-right: none;
  }

  .metadataText {
    font-size: 0.9rem;
    color: var(--neutral600);
    font-weight: 400;
  }

  .metadataIcon {
    color: var(--neutral600);
    font-size: 1rem;
  }
}

/*
* renderIndicationTag
*/

.indicationTag {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.375rem 0.4375rem;
  gap: 0.625rem;
  border-radius: 0.25rem;
  border: 1px solid var(--Neutral-1_White, #fff);
}
.text {
  color: var(--neutral1);
  text-align: center;
  text-overflow: ellipsis;
  font-size: 0.7rem;
  font-weight: 500;
  line-height: 0.75rem;
  letter-spacing: -0.0075rem;
}
.updated {
  background: var(--primary-main5);
}
.new {
  background: var(--green-primary);
}
