@import 'src/styles/basics';

.background {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 11;
  display: flex;
  flex-direction: column;
  background-color: rgba(var(--primary-main5), 0.3);
  backdrop-filter: blur(8px);
}

.root {
  display: flex;
  flex-direction: column;

  label {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
    color: $grayDark;
  }
}
.buttons {
  display: flex;
  margin-top: 32px;
  justify-content: center;
  gap: 16px;

  & > * {
    flex: 1;
  }
}

.noMargin {
  margin: 0px;
}

.textArea {
  padding: 16px;
  border-radius: 8px;
  border: 1px solid $lightDarkGray;
  font-size: 16px;
  font-weight: 500;
  line-height: 115%;
}

.tagWrapper {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 0px;
  gap: 15px;
  margin-top: 8px;
  margin-bottom: 32px;

  .tag {
    display: flex;
    padding: 8px 20px;
    margin-right: 0px;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    background: $lightGray2;
    font-size: 15px;
    font-weight: 400;
    line-height: 140%;

    &.selected {
      background: var(--primary-main5);
      color: $white;
    }
  }
}
