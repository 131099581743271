@import 'src/styles/basics';

.dropdownInnerWrapper {
  padding: 0.5rem;
}

.logoWrapper {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  .logoIcon {
    margin-left: 12px;
    height: 20px;
    width: 20px;
    color: white;
    position: relative;
    top: 3px;
  }

  .activeLogoIcon {
    color: grey;
  }

  .logo {
    height: 2rem;
    object-fit: contain;
  }
}

.menuIcon {
  margin-right: 8px;
}
