@import 'src/styles/basics';

.root {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.input {
  margin: 0.25rem;
  border-radius: 4px;
  text-align: center;

  border: 1px solid var(--gray-medium-light);
  background: var(--gray-light);
  color: var(--primary-text-positive);

  @media #{$mobile} {
    font-size: 1rem;
    width: 2.5rem;
    height: 2.5rem;
  }

  @media #{$tablet} {
    font-size: 1.25rem;
    width: 2.5rem;
    height: 3rem;
  }

  @media #{$desktop} {
    font-size: 1.5rem;
    width: 3rem;
    height: 3.5rem;
  }

  &:first-child {
    margin-left: 0;
  }

  -moz-appearance: textfield;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
