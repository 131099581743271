.childrenWrapper {
  &.showArrowIcon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }

  &:hover {
    cursor: pointer;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.bordered {
    border: 1px solid var(--neutral5);
    border-radius: 0.5rem;
    height: 3rem;
  }
}

.arrowIcon {
  font-size: 1.25rem;
}
