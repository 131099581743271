.root {
  flex: 1;
  margin: var(--margin-4);
}

.allSettingButton {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.viewButton {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: none;
  background: none;
  &:hover {
    opacity: 0.8;
  }
}

.subtitle {
  text-align: left;
  word-wrap: break-word;
}
