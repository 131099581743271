.lang {
  height: 20px;
  width: 20px;
  float: right;
  cursor: pointer;
  opacity: 0.6;
}

.image {
  margin: -15px auto 5px;
  display: block;
  height: 120px;
}

.selectLang {
  background: transparent;
  align-self: center;
  border: 0;
  margin: 3px 0;
}

.langList {
  display: flex;
  flex-direction: column;
}

.button {
  margin-right: auto;
  margin-left: auto;
}

.popup {
  max-width: 400px;
}
