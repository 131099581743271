@import 'src/styles/basics';

.root {
  flex: 1;
  padding: 0 var(--margin-1);
}

.table {
  flex: 1;
  margin-bottom: var(--margin-2);
}

.tableHeader {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 var(--margin-2);
  align-items: center;
  height: 2.5rem;
  background-color: var(--neutral6);
  border: 1px solid var(--neutral7);
  border-radius: 5px;
}

.column {
  color: var(--neutral4);
  flex: 0 0 15%;
}

.addNew {
  display: flex;
  gap: var(--margin-2);
  width: 100%;
  margin: var(--margin-2) 0 0 0;
}

.tableRow {
  display: flex;
  justify-content: space-between;
  padding-left: var(--margin-2);
  align-items: center;
  border-bottom: 1px solid var(--neutral4);
  align-items: center;
  min-height: 3.5rem;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.columnValue {
  flex: 0 0 15%;
  padding: 0.5rem 0;
  justify-content: center;
  text-overflow: ellipsis;
  overflow: hidden;
}

.professionalValue {
  font-size: 0.65rem !important;
  font-weight: 400;
}

.nameValue {
  font-weight: 600;
}

.link {
  color: var(--primary-main6) !important;
  cursor: pointer;
}

.actionColumn {
  display: flex;
  gap: var(--margin-1);
  color: var(--neutral4);
  justify-content: flex-end;
  margin-right: var(--margin-1);
}

.icon {
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
}

.buttonIcon {
  padding: var(--margin-1);
}

.shareIcon {
  color: var(--primary-main6);
}

.removeIcon {
  color: var(--red);
}
