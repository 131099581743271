/*
  * renderIcon
  */
.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  padding: 0rem 0.31rem 0rem 0.38rem;
  flex-shrink: 0;
}

.knowledgeIcon {
  padding: 0rem 0.2rem 0rem 0.2rem;
  margin: 0.4rem 0 0 0.4rem;
}

.checklistIcon {
  padding: 0.3rem 0.1rem 0rem 0.3rem;
  margin: 0.2rem 0 0 0.4rem;
}

.labIcon {
  padding: 0rem 0.2rem 0rem 0.2rem;
}

/*
  * renderTags
  */
.tags {
  display: flex;
  max-width: 100%;
  height: 1.25rem;
  gap: 0.5rem;
}

.tag {
  display: flex;
  height: 1.25rem;
  padding: 0rem 0.5rem 0rem 0.5rem;
  align-items: center;
  border-radius: 3.75rem;
  border: 1px solid var(--neutral600);
  background: var(--primary-main1);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  p {
    color: var(--neutral600) !important;
    font-size: 0.6875rem !important;
    font-weight: 600;
    line-height: 0.75rem !important;
    letter-spacing: -0.01rem;
    white-space: nowrap !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.contentType {
  display: flex;
  align-items: center;
  gap: 0.25rem;

  p {
    color: var(--neutral5) !important;
    font-size: 0.71875rem !important;
    text-align: right;
    font-weight: 600;
    line-height: 120% !important;
    text-wrap: nowrap;
  }
}

/*
* renderLabels
*/
.labelsContainer {
  height: 1.37rem;
  display: flex;
}

/*
* renderMetadata
*/
.metadata {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  gap: 0.5rem;
}

.metadataContainer {
  display: flex;
  padding-right: 0.75rem;
  align-items: center;
  gap: 0.25rem;
  border-right: 1px solid var(--neutral4);

  .metadataText {
    color: var(--neutral600);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
  }

  .metadataIcon {
    color: var(--neutral600);
    font-size: 1.125rem;
  }
}

/*
* renderIndicationRibbon
*/

.ribbon {
  position: absolute;
  bottom: -0.86rem;
  left: 0;
}

.text {
  position: absolute;
  bottom: 1.8rem;
  left: 0;
  display: -webkit-box;
  width: 4.8rem;
  transform: rotate(45deg);
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--neutral1);
  text-align: center;
  text-overflow: ellipsis;
  font-size: 0.62rem;
  font-weight: 700;
  line-height: 0.46rem;
  letter-spacing: 0.025rem;
}
.updated {
  fill: var(--blue-primary);
}
.new {
  fill: var(--green-primary);
}
