@import 'src/styles/basics';
.root {
  display: flex;
  flex-direction: column;
  padding: $page-padding;
}

.searchWrapper {
  flex: 0 0;
}

.searchHeader {
  margin-bottom: 0.25rem;
}

.search {
  display: flex;
  width: 19rem;
  height: 3rem;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid var(--neutral4);
  background: var(--neutral9);

  color: var(--gray-dark) !important;

  font-size: 1rem !important;
  font-weight: 500;
  line-height: 115%;
  letter-spacing: 0.0125rem;
}

.emptyRoot {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh;
}

.emptyHeader {
  color: $searchEmptyScreenHeader;
  font-size: 22px;
  font-weight: 300;
  margin-bottom: 50px;
}

.emptyImage {
  object-fit: contain;
  object-position: center;
  width: 12rem;
}

.main {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  margin-top: 1rem;
  gap: 1rem;
}

.results {
  flex: 3;
}

.filters {
  padding: $page-padding;
  background: var(--neutral1);
  display: flex;
  flex-direction: column;
  min-width: 200px;
  width: 20vw;
  height: fit-content;
  border-radius: 0.5rem;
  border: 1px solid var(--neutral4);
}
