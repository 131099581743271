@import 'src/styles/basics';

.content {
  text-align: center;
  margin-bottom: var(--margin-2);
}

.buttons {
  display: flex;
  margin-top: 15px;
  gap: var(--margin-1);
}
