@import 'src/styles/basics';

.box {
  flex: 0 0 180px;
  transition: all 200ms ease-in-out;
  position: relative;

  &.inActive {
    opacity: 0.5 !important;
  }

  &:hover,
  &.active {
  }
}

.createBox {
  cursor: pointer;
  justify-content: center;
  align-items: center;
}

.boxWrapper {
  display: flex;
  overflow: hidden;
  border-radius: 15px;
  background: $lightGray;
  flex-direction: column;
  height: 210px;
  transition: all 200ms ease-in-out;
}

.image {
  width: 100%;
  height: 124px;
  border: 0;
  padding: 0;
  background: white;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: bottom;
  }
}

.headerContainer {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 7px;
  line-height: 1.29;
  font-size: 12px;
  flex: 1 45px;
  border: 0;
  text-align: left;
  background: transparent;
  cursor: pointer;

  .arrow {
    display: block;
    height: 15px;
    width: 20px;
    padding-left: 5px;
    flex: 0 0 20px;
    margin-left: auto;
  }
}

.text {
  display: inline-block;
  border: 1px solid transparent !important;
  background: transparent !important;
  color: $boxContentTextColor;
  font-size: 12px;
  line-height: 15px;
  flex: 1;

  &[disabled] {
    cursor: pointer;
    pointer-events: none; // TODO: need to allow scroll in long title
    color: $boxContentTextColor !important;
    background: transparent !important;
  }

  &:focus,
  .admin:hover & {
    border-color: rgba($black, 0.1) !important;
    background: transparent !important;
  }
}

.actionItem {
  height: 32px;
  width: 32px;
  position: absolute;
  top: 38px;
  background: $veryLightBlue;
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  border-radius: 50%;
  padding: 6px;
  cursor: pointer;

  .admin:hover & {
    opacity: 1;
  }
}

.deleteItem {
  right: 30px;
}

.dragItem {
  right: 102px;
  padding: 8px !important;
}

.editItem {
  right: 66px;
  padding: 8px !important;
}

.disabled {
  cursor: default;
  background-color: $lightGray;
}
