.error {
  color: var(--red-medium);
  font-weight: 700;
  margin-top: 1rem;
  display: block;
}

.contact {
  margin-top: 1rem;

  .link {
    color: var(--primary-main5) !important;
    font-weight: 700;
  }
}
