@import 'src/styles/basics';

.buttonIcon {
  margin: 0;
  padding-right: 0;
}

.removeIcon {
  color: var(--red);

  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
}

.externalUrl {
  opacity: 1 !important;
}

.content {
  width: 100%;
}

.header {
  padding: var(--margin-2) 0;
}

.title {
  margin-right: var(--margin-2);
}

.modalTitle {
  color: var(--primary-main8) !important;
  font-weight: 700 !important;
  font-size: $modalHeaderFontSize;
  letter-spacing: -0.04em;
}

.header {
  display: flex;
}
