@import 'src/styles/basics';

.data {
  flex-wrap: wrap;
}

.detail {
  margin: 10px 0;
}

.detailContent {
  white-space: pre-line;
  word-break: break-all;
}

.breadcrumbs {
  margin-top: 0 !important;
  margin-bottom: 10px;
}

.button {
  align-self: center;
}

.tags {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -20px;
}

.tagIcons {
  height: 45px;
  width: 45px;
}
