@import 'src/styles/basics';

.editMode {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  margin: 10px 10px 40px 10px;
  padding: 0 30px 0 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  Button {
    background-color: rgba($white, 0.4);
    color: $white;
    border: unset;
    border-radius: 5px;
    margin-left: 10px;
    max-width: 80px;
    height: 30px;

    &:hover {
      background-color: rgba($white, 0.4);
    }
  }
}

.contentHeader {
  display: flex;
  flex-direction: column;
  position: relative;

  padding-right: 5rem; // Padding for the icons
  padding-bottom: var(--margin-2);
  border-bottom: 1px solid var(--neutral5);
  margin: var(--margin-2) 0;

  .iconsWrapper {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    gap: 10px;
  }

  .shareIcon {
    color: var(--primary-main5);
    font-size: 26px;
  }

  .bookmarkIcon {
    color: var(--primary-main5);
    font-size: 22px;
    cursor: pointer;
  }

  .bookmarkWrapper {
    background: transparent;
    border: none;
  }

  .title {
    color: var(--primary-main8);
  }

  .titleInput {
    color: var(--primary-main8);
    font-size: var(--font-size-h1);
    font-weight: 500;
    padding: 0;
  }

  .subtitle {
    color: var(--primary-main5);
    margin-bottom: var(--margin-1);
  }

  .subtitleInput {
    color: var(--primary-main5);
    font-size: var(--font-size-h3);
    font-weight: 600;
    padding: 0;
  }

  .metadata {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }

  a {
    color: var(--primary-main5);
    font-weight: 700 !important;
    margin: 0 3px;
    text-decoration-line: underline;
  }
}

.thumbnail {
  border-radius: 10px 0 0 10px;
  height: 90px;
  width: 100px;
  margin: 0 10px 10px 0;
  object-fit: cover;
  object-position: center;
}

.thumbnailEditor {
  border-radius: 10px;
  height: 90px;
  width: 100%;
  border: 2px dashed transparent;
  margin: 0 0.5rem 0.5rem 0;
}

.uploadFile {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 55px;
  margin: 0;
  background-color: $white;
  border: 2px dashed transparent;
  border-radius: 10px;
}

.urlContent {
  margin-top: 30px;
  a {
    font-size: 16px;
  }
}

.url {
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  a {
    color: unset;
  }
}

.expirationButtons {
  display: flex;
  flex-direction: row;
}

.button {
  margin-right: 5px;
  margin-left: 5px;
}

.link {
  background: none;
  border: none;
  color: var(--primary-main5);
  flex: none;
  padding-left: 5px;
  text-decoration: underline;
  transition: 300ms opacity ease-in-out;
  font-weight: 500;

  &:hover {
    text-decoration: none;
    opacity: 0.8;
  }
}

.buttonWrapper {
  display: flex;
  flex-direction: row-reverse;
  gap: 16px;
}

.content {
  font-weight: 600;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: var(--margin-1);
  gap: var(--margin-1);
}

.spaceLeft {
  margin-left: 0.2rem;
}

.spaceRight {
  margin-right: 0.2rem;
}

.spaceHorizontal {
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}

.contributors {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.2rem;

  a {
    margin: 0;
    color: var(--primary-main6) !important;
    font-weight: 500;
  }
}

.contributorTooltip {
  padding: 0 0.125rem 0.125rem;
  line-height: 115%;
}

.knowledgeActions {
  border-top: 1px solid var(--neutral5);
  padding: var(--margin-2) 0;
  display: flex;
  flex-direction: column;
  gap: var(--margin-05);
}

.keywordsSection {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: var(--margin-05);
}

.headerActionsButton {
  white-space: normal;
  height: auto;
}

.backTop {
  margin-bottom: var(--margin-2);
}

.ownerLink {
  margin-right: 0 !important;
  text-decoration: none;
}

.updatedBy {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 0.25rem;
}

.bold {
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.00938rem;
  margin-right: 0.25rem;
}
