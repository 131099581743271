@import 'src/styles/basics';

.notMember {
  margin-top: 1rem;
}

.link {
  color: var(--primary-main5) !important;
  font-weight: 700;
}

.error {
  color: var(--red-medium);
  font-weight: 700;
  display: block;
  margin-bottom: var(--margin-1);
}

.loader {
  height: 10px !important;
}
