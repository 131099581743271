.container {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;

  &:hover {
    .overlay {
      opacity: 0.8;
    }
  }
}

.imageContainer {
  width: 100%;
  height: 100%;

  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.overlay {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
}
