@import 'src/styles/basics';

.root {
  min-height: 70px;
  background: $knowledgeItemBackground;
  border-radius: 15px;
  margin: 15px 0 0;
  display: flex;
  overflow: hidden;
  align-items: center;
  cursor: pointer;

  &.light {
    background: $knowledgeItemBackgroundLight;
  }
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
  margin: 14px 5px 14px 20px;
  flex: 1;

  .title {
    margin: 0 0 -8px 0;
  }

  .check {
    margin-top: 8px;
  }

  .value {
    margin: 0;
  }
}

.thumbnailWrapper {
  width: 80px;
  display: flex;
  align-self: stretch;
}

.thumbnail {
  object-position: center;
  object-fit: cover;
  align-self: stretch;
  height: 100%;
  width: 100%;
}

.buttons {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  padding-left: 15px;
  justify-content: center;
  align-items: center;
  margin-right: 7px;
}

.buttonWrapper {
  height: 30px;
  width: 30px;
  background: $knowledgeItemIconWrapperWeb;
  border: 0;
  margin-right: 7px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 300ms ease-in-out;

  .light & {
    background: $knowledgeItemIconWrapper;
  }

  &:hover,
  &:disabled {
    background: $knowledgeItemIconWrapperWebHover;
  }
}

.informationIcon {
  height: 13px;
  width: 13px;
}

.commentIcon {
  height: 18px;
  width: 16px;
}

.favoritesIcon {
  height: 25px;
  width: 25px;
}

.dragIconWrapper {
  cursor: move;
}

.dragIcon {
  height: 14px;
  width: 24px;
}

.editIcon {
  height: 14px;
  width: 24px;
}

.deleteIcon {
  height: 16px;
  width: 24px;
}

.changeDirectory {
  height: 14px;
  width: 24px;
}
