@import 'src/styles/basics';

.root {
  min-height: 70px;
  background: $knowledgeItemBackground;
  border-radius: 15px;
  margin: 15px 0 0;
  display: flex;
  overflow: hidden;
  align-items: center;

  &.light {
    background: $knowledgeItemBackgroundLight;
  }
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
  margin: 14px 5px 14px 20px;
  flex: 1;

  .textFields {
    width: 80%;
  }

  .title {
    font-size: $itemTitleFontSize;
    line-height: 1.5;
    color: $knowledgeItemTitle;
    font-weight: $itemTitleFontWeight;
    flex: 1 0 50%;
    padding-right: 10px;
    align-items: center;
    display: flex;
    background: transparent !important;
    border-color: transparent !important;

    &:disabled {
      color: $knowledgeItemTitle !important;
      background: transparent !important;
      cursor: pointer;
      pointer-events: none;
      -webkit-text-fill-color: $knowledgeItemTitle; /* Override iOS / Android font color change */
      -webkit-opacity: 1; /* Override iOS opacity change affecting text & background color */
    }

    &:focus,
    .admin &:hover {
      border-color: rgba($black, 0.1) !important;
      background: transparent !important;
    }
  }

  .valueWrapper {
    margin: 0;
  }

  .value {
    padding-left: 10px;
    padding-right: 0px;
  }
}

.thumbnailWrapper {
  height: 45px;
  width: 45px;
  align-self: center;
  cursor: default;
}

.itemIcon {
  width: 55px;
  height: 55px;
  margin: 0px 0 0 -5px;
  padding: 0px 0px 5px 0;
}

.thumbnail {
  object-position: bottom;
  object-fit: contain;
  align-self: stretch;
  height: 100%;
  width: 100%;
}

.buttons {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  justify-content: center;
  align-items: center;
  margin-right: 7px;
}

.buttonWrapper {
  height: 30px;
  width: 30px;
  background: $knowledgeItemIconWrapperWeb;
  border: 0;
  margin-right: 7px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 300ms ease-in-out;
  flex: 1;

  .light & {
    background: $knowledgeItemIconWrapper;
  }

  &:hover,
  &:disabled {
    background: $knowledgeItemIconWrapperWebHover;
  }
}

.informationIcon {
  height: 13px;
  width: 13px;
}

.deleteIcon {
  height: 16px;
  width: 16px;
}

.favoritesIcon {
  height: 25px;
  width: 25px;
}

.dragIconWrapper {
  cursor: move;
}

.dragIcon {
  height: 14px;
  width: 24px;
}

.editIcon {
  height: 14px;
  width: 24px;
}

.referenceIcon {
  height: 15px;
  width: 16px;
}
