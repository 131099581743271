.root {
  position: fixed;
  top: var(--header-height);
  bottom: 0;
  min-width: 500px;
  width: 40%;
  background: var(--white);
  right: -40vw;
  z-index: 10;
  box-shadow: -5px 10px 30px 0 rgba(0, 0, 0, 0.2);
  transition: all 500ms ease-in-out;
  overflow: scroll;
  transform: translateX(45vw);
  display: flex;
  flex-direction: column;

  &.open {
    transform: translateX(-40vw);
  }
}

.close {
  position: absolute;
  right: var(--margin-2);
  top: var(--margin-2);
  cursor: pointer;
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  font-size: var(--font-size-h2);
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.5;
  }
}

.header {
  display: flex;
  flex-direction: column;
  padding: var(--margin-3) var(--margin-3);
  border-bottom: 1px solid var(--neutral4);
  position: relative;

  h2 {
    display: flex;
    align-items: center;
  }
}

.chatId {
  font-size: var(--font-size-label);
  font-weight: 400;
  letter-spacing: 0.32px;
  color: var(--neutral6);
  margin-top: var(--margin-05);
}
