@import 'src/styles/basics';

.root {
  .title {
    color: var(--primary-main8);
    font-size: 22px;
    margin: 35px 0 15px 0;
  }

  .container {
    flex-shrink: 0;
    border-radius: 8px;
    background: $white;
    padding: 15px;
    color: $primary;
    font-size: 14px;
    font-style: normal;
    line-height: normal;

    .description {
      font-weight: 600;
    }

    .button {
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: flex-end;
      font-weight: 700;
      font-size: 12px;
      gap: 5px;

      .buttonLabel {
        font-size: 12px;
      }

      .icon {
        font-size: 18px;
      }
    }

    hr {
      margin: 15px 0;
      opacity: 0.5;
    }
  }
}
