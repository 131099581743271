@import 'src/styles/basics';

.tag {
  border-radius: $input-radius;
  background-color: $white;
  margin: 4px !important;
  padding: 5px 8px !important;
  color: rgba($primary, 0.8) !important;
  text-align: center;
  font-weight: 700 !important;
  font-size: $input-font-size;
  color: $primary;
  white-space: pre-line !important;
  max-width: 100%;
  text-align: left;
}

.closeIcon {
  height: 19px;
  width: 13px;
}
