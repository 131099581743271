.topSection {
  display: flex;
  justify-content: space-between;
  gap: var(--margin-1);
}

.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--margin-1);
}
.header {
  display: flex;
  flex-direction: column;
  gap: var(--margin-1);
  padding-bottom: var(--margin-3);
}

.avatarWrapper {
  margin-right: var(--margin-2);
}

.backButton {
  color: var(--primary-main6);
  font-size: 1rem;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: -0.01rem;

  &:hover {
    cursor: pointer;
  }
}

.buttons {
  display: flex;
  gap: var(--margin-1);
}

.title {
  color: var(--primary-main8);
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -0.0175rem;
}

.subtitle {
  color: var(--primary-main8);
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.00938rem;
}

.fieldWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding: 0.75rem 0;
}

.empty {
  p {
    color: var(--neutral5);
  }
}

.label {
  color: #333;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.0075rem;
}

.row {
  display: flex;
  justify-content: space-between;
  padding-right: var(--margin-1);
  padding-bottom: var(--margin-1);
}

.value {
  color: var(--primary-main9);
  font-size: 1rem;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: -0.01rem;
  overflow-wrap: anywhere;
}

.icon {
  color: var(--primary-main6);
  font-size: 1.25rem;
  margin-left: var(--margin-1);

  &:hover {
    cursor: pointer;
  }
}
