@import 'src/styles/basics';

.hr {
  width: 100%;
  border-top: 1px dashed var(--neutral5);
  margin: 0.75rem 0;
}

.providerButton {
  background-color: var(--white);
  border: 1px solid var(--neutral4);
  border-radius: var(--box-radius);
  margin-top: var(--margin-1);
  margin-right: var(--margin-1);
  transition: all 0.4s ease-in-out;

  &:hover:not(.disabled) {
    border-color: var(--primary-secondary);
  }
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.providerLogo {
  height: 2rem;
  margin: var(--margin-1);
  object-fit: contain;
}

.providers {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}
