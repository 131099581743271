@import 'src/styles/basics';

.root {
  flex: 1;
  width: 100%;
  position: relative;

  p {
    font-size: 14px;
  }
}

.wrapper {
  border: 2px dashed transparent;
  background: $inputEditorBackground;
  border-radius: 4px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  flex-direction: row;

  &:has(.deleteButton) {
    justify-content: space-between;
  }
}

.labelWrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  flex-direction: column;
  text-align: start;
  justify-content: center;

  .fileLabel {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 700;
  }

  .readyLabel {
    font-size: 14px;
    font-weight: 500;
  }
}

.input {
  border: none;
  font-size: 17px;
}

.input::placeholder {
  color: $inputEditorPlaceholder;
}

.error {
  text-align: left;
  font-size: 14px;
  font-weight: 700;
  color: $inputErrorColor;
}

.thumbWrapper {
  font-size: 12px;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 0 32px 0 18px;

  .thumb img {
    width: 63px;
    height: 63px;
    margin-right: 10px;
  }
}

.preview {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 1em;
}

.uploadIcon {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    height: 40px;
    width: 40px;
  }
}

.deleteIcon {
  height: 25px;
  width: 25px;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 20px;
  padding: 4px;
  background-color: $white;
  box-shadow: 0 0 0 1px rgba($primary, 0.25);
  cursor: pointer;
}

.deleteButton {
  margin: 0 1rem;
  &:hover {
    border: 1px solid #ff7875;
    background: rgba(255, 77, 79, 0.06);
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  }
}
