@import 'src/styles/basics';

.paddingBottom {
  padding-bottom: 4rem;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.anchor {
  display: block;
  height: 5px;
  width: 5px;
  position: relative;
  top: calc((2rem + var(--header-height)) * -1);
  z-index: -1;
}

.header {
  display: flex;
  align-items: center;
  position: relative;
}

.headerText {
  font-weight: $headerFontWeight !important;
  font-size: $headerFontSize !important;
  color: var(--primary-main8) !important;
  padding: 5px !important;
  margin-left: -5px !important;
  background: transparent !important;
  border-color: transparent !important;

  &:disabled {
    color: var(--primary-main8) !important;
    background: transparent !important;
    -webkit-text-fill-color: var(--primary-main8); /* Override iOS / Android font color change */
    -webkit-opacity: 1; /* Override iOS opacity change affecting text & background color */
  }

  &:focus,
  .admin &:hover {
    border-color: rgba($black, 0.1) !important;
    background: transparent !important;
  }
}

.items {
  display: flex;
  margin-top: var(--margin-2);
  flex-direction: row;
  flex-wrap: wrap;
  transition: max-height 400ms ease-in-out;
  gap: 0.88rem;
}

.itemsColumn {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
}

.addIcon {
  height: 40px;
  width: 40px;
}

.share {
  cursor: pointer;
}

.adminActions {
  display: none;
  transition: 300ms ease-in-out opacity;

  .admin & {
    display: flex;
    opacity: 0;
    margin-left: 15px;
  }

  .admin:hover & {
    opacity: 1;
  }
}

.deleteIcon {
  height: 25px;
  width: 25px;
  cursor: pointer;
  margin-right: 15px;
}

.editIcon {
  height: 25px;
  width: 20px;
  cursor: pointer;
  margin-right: 15px;
}

.directoryIcon {
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.changeOrderIcon {
  cursor: pointer;
  height: 24px;
  width: 24px;
  margin: 0 10px;
}

.changeOrderUp {
  transform: rotate(-90deg);
}

.changeOrderDown {
  transform: rotate(90deg);
}
