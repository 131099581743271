.root {
  display: flex;
  flex-direction: column;
  gap: var(--margin-2);
  height: 100%;
  position: relative;
}

.topBorder {
  margin-top: var(--margin-2);
  border-top: 1px solid var(--neutral5);
  padding-top: var(--margin-2);
  flex-grow: 1;
}

.title {
  color: var(--primary-main8);
  letter-spacing: -1%;
}

.commentsWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  overflow-y: auto;
}

.comment {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border: 1px solid var(--neutral4);
  border-radius: 0.5rem;
  padding: 0.75rem;
}

.paddingTop {
  padding-top: var(--margin-2);
}

.author {
  display: flex;
  gap: 0.625rem;

  h4 {
    text-transform: capitalize;
  }

  span {
    color: var(--neutral5);
    font-size: 0.875rem;
    letter-spacing: -1%;
  }
}

.text {
  // Markdown styles
  white-space: pre-wrap;

  p,
  li {
    font-size: 0.938rem;
  }

  li {
    list-style-type: disc;
    margin-left: 1rem;
  }
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    border: none;
    background: none;
    font-weight: 700;
    padding: 0;
    margin: 0;

    transition: all 0.2s ease-in-out;
  }

  .reply {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    color: var(--primary-main6);
    &:hover {
      opacity: 0.5;
    }
  }

  .delete {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--neutral6);
    margin-left: auto;
    &:hover {
      color: #ff5a65;
    }
  }
}

.form {
  display: flex;
  border: 1px solid var(--neutral4);
  background-color: var(--neutral2);
  border-radius: 0.5rem;
  button {
    border: none;
    background: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
  }

  .sendCancelWrapper {
    display: flex;
    gap: 0.625rem;
    justify-content: flex-end;
    align-items: center;
    padding: 0 0.625rem;
  }

  .send {
    color: var(--primary-main5);
    &:hover {
      opacity: 0.8;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .cancel {
    color: var(--neutral5);
    &:hover {
      opacity: 0.8;
    }
  }

  .loading {
    color: var(--primary-main5);
    align-self: center;
    padding: 0 0.625rem;
  }
}

.header {
  padding: var(--margin-2) 0;

  a {
    font-weight: 500;
    letter-spacing: -0.14px;
    text-decoration: underline !important;

    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
}

.noComments {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: var(--margin-2);
  background-color: var(--neutral2);

  border-radius: 0.5rem;

  svg {
    color: var(--primary-main7);
  }

  p {
    font-size: 0.938rem;
    color: var(--primary-main7) !important;
  }
}

.stickyInput {
  position: sticky;
  bottom: -1rem;
  box-shadow: 0px -1rem 0.5rem white;
  border: 0.1rem solid white;
  border-bottom-width: 1rem;
}

.scrollWithMargin {
  scroll-margin: 5rem;
}
