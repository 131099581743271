@import 'src/styles/basics';

.root {
  position: relative;
  background-color: var(--neutral3);
  padding: 1.5rem 0 var(--margin-3);
  min-height: 100%;

  display: flex;
  flex-direction: column;

  .toc {
    overflow-y: auto;
    overflow-x: hidden;
    min-width: 16rem;
  }

  .refresh {
    padding: 0.75rem 1rem;
    font-weight: 500;
    font-size: 1.25rem;
    color: var(--primary-main5);
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    p {
      color: var(--primary-main5);
      font-size: 0.85rem;
      line-height: 120%;
    }

    .refreshIcon {
      margin-right: 0.65rem;
    }

    &:hover {
      background-color: var(--neutral4);
    }
  }
}

.tocTitle {
  list-style: none;
  position: relative;
  padding-left: 1rem;
}

.tocTitle::before {
  content: attr(data-bullet);
  position: absolute;
  left: 0;
}
