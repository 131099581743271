@import 'src/styles/basics';

.section {
  border-top: 1px solid var(--neutral5);
  padding: var(--margin-2) 0;
  display: flex;
  flex-direction: column;
  line-height: 100%;

  &:first-of-type {
    border-top: none;
  }

  h6 {
    font-size: var(--font-size-label);
    display: block;
    white-space: pre-line;
    font-weight: 400;
  }
}
