@import 'src/styles/basics';

.root {
  display: flex;
  flex: 1;
  min-height: 100vh;
  flex-direction: column;
  background-color: var(--primary-snowy);
}

.header {
  background-color: var(--neutral6);
  display: flex;
  position: fixed;
  top: 0;
  width: 100%;

  @media #{$wide} {
    height: 6rem;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 1.185rem;
  }

  @media #{$mobile} {
    justify-content: center;
    height: 4rem;
  }

  .logo {
    align-self: center;

    @media #{$wide} {
      height: 3rem;
    }

    @media #{$mobile} {
      height: 2rem;
    }
  }

  .link {
    font-weight: 700;
    color: var(--primary-text-negative);
    align-self: flex-end;
    font-family: Inter;

    @media #{$mobile} {
      display: none;
    }
  }
}

.panda {
  object-fit: contain;
  object-position: bottom;
  position: absolute;
  bottom: -4rem;
  align-self: center;
  max-width: 85%;

  @media #{$wide} {
    height: 25rem;
    width: 16rem;
  }

  @media #{$mobile} {
    height: 2rem;
  }
}

.wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

.title {
  margin-bottom: 1.5rem;
}

.content {
  margin-bottom: 1rem;
}

.lang {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
