@import 'src/styles/basics';

.content {
  text-align: center;
}

.buttons {
  display: flex;
  margin-top: 15px;
}

.button {
  margin-right: 5px;
  margin-left: 5px;
}

.categorySelect {
  margin-right: 15px;
}

.categoriesTypes {
  margin-bottom: 15px;
}

.parts {
  display: flex;
}

.part {
  flex: 1;
  margin-left: 40px;
  &:first-child {
    margin-left: 0;
  }
}

.checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.checkLabel {
  margin-left: 5px;
}

.itemId {
  font-size: 10px;
  color: $lightGray3;
  float: right;
  margin-right: 5px;
}

.expirationButtons {
  display: flex;
  flex-direction: row;

  button {
    background: transparent;
    border: 0;
    text-decoration: underline;
    color: $filterByTypeTextActive;
    margin: -15px 0px 15px 0px;
  }
}
