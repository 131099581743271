@import 'src/styles/basics';

.root {
  min-height: 60px;
  background: $knowledgeItemBackground;
  border-radius: 15px;
  margin: 15px 0 0;
  display: flex;
  overflow: hidden;
  align-items: center;
  cursor: pointer;

  &.light {
    background: $knowledgeItemBackgroundLight;
  }
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
  margin: 14px 5px 14px 20px;
  flex: 1;

  .title {
    font-size: $itemTitleFontSize;
    line-height: 1.5;
    color: $knowledgeItemTitle;
    font-weight: $itemTitleFontWeight;
    flex: 1 0 50%;
    padding-left: 10px;
    align-items: center;
    display: flex;
    background: transparent !important;
    border-color: transparent !important;

    &:disabled {
      color: $knowledgeItemTitle !important;
      background: transparent !important;
      cursor: pointer;
      pointer-events: none;
    }

    &:focus,
    .admin &:hover {
      border-color: rgba($black, 0.1) !important;
      background: transparent !important;
    }
  }
}

.buttons {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  padding-left: 15px;
  justify-content: center;
  align-items: center;
  margin-right: 7px;
}

.buttonWrapper {
  height: 30px;
  width: 30px;
  background: $knowledgeItemIconWrapperWeb;
  border: 0;
  margin-right: 7px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 300ms ease-in-out;

  .light & {
    background: $knowledgeItemIconWrapper;
  }

  &:hover {
    background: $knowledgeItemIconWrapperWebHover;
  }
}

.editIcon {
  height: 16px;
  width: 24px;
}

.deleteIcon {
  height: 16px;
  width: 24px;
}

.thumbnailWrapper {
  height: 45px;
  width: 45px;
  display: flex;
  align-self: stretch;
  align-content: center;
  cursor: default;
}

.tag {
  margin-right: 5px;
}
