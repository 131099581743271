.viewSelector {
  display: flex;
  flex-direction: row;
}
.viewButton {
  display: flex;
  width: 50%;
  text-wrap: nowrap;
  white-space: nowrap;
  padding: 0rem 1rem;
  justify-content: center;
  align-items: center;
  font-family: Inter;
  font-size: 0.85rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}
