@import 'src/styles/basics';

.buttons {
  display: flex;
  flex-direction: row;
}

.extendTime {
  color: var(--primary-main5);
  background: transparent;
  border: 0;
  padding: 0 var(--margin-1);
  font-weight: 600;
  display: inline-block;
  position: relative;
  white-space: nowrap;
  text-decoration: underline;
  transition: all 0.2s ease-in-out;

  &:hover {
    text-decoration: none;
    opacity: 0.8;
  }

  &:first-of-type {
    padding-left: 0;
  }

  &:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 20%;
    right: 0;
    width: 1px;
    background: var(--neutral5);
    height: 60%;
    margin-left: 5px;
  }
}
