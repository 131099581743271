@import 'src/styles/basics';

.paddingBottom {
  padding-bottom: 4rem;
}

.pdfIcon {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}

.generatePdf {
  z-index: 99;
  background: var(--primary-main5);
}

.actionIcon {
  color: var(--neutral3);
  text-align: center;
  align-content: center;
}

.resetPDF {
  z-index: 99;
  bottom: 80px;
  background: var(--primary-main5);
}

.resetPDFIcon {
  height: 30px;
  width: 30px;
}
