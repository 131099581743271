@import 'src/styles/basics';

.root {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 5px 0;
}

.smallWrapper {
  margin: 5px 0;
}

.input {
  border: none;
  height: 55px;
  font-size: 17px;
}

.smallInput {
  border: none;
  height: 35px;
  font-size: 14px;
}

.user {
  border-bottom: 1px solid $inputBorderColor;
}

.user::placeholder {
  color: $inputPlaceholder;
}

.editor {
  background: $inputEditorBackground;
  color: $primary;
  border-radius: 4px;
  padding: 0 7px;
  font-size: 14px;
}

.editor::placeholder {
  color: $inputEditorPlaceholder;
}

.clean {
  color: $primary;
  font-size: $itemTitleFontSize;
  font-weight: $itemTitleFontWeight;

  &::placeholder {
    color: $inputPlaceholder;
  }

  &:focus {
    box-shadow: 0 0 0 2px rgba(var(--primary-main5), 0.25);
  }
}

.cleanTitle {
  color: $primary;
  font-size: $headerFontSize;
  font-weight: $headerFontWeight;

  &::placeholder {
    color: $inputPlaceholder;
  }

  &:focus {
    box-shadow: 0 0 0 2px rgba(var(--primary-main5), 0.25);
  }
}

.border {
  color: $primary;
  font-size: $itemTitleFontSize;
  font-weight: $itemTitleFontWeight;
  background: $white;
  border-radius: 4px;
  padding: 0 7px;
  box-shadow: 0 0 0 1px $inputBorderColor;

  &::placeholder {
    color: $inputPlaceholder;
  }
}

.error {
  text-align: left;
  margin-top: 4px;
  color: $inputErrorColor;
}
