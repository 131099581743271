@import 'src/styles/basics';

.root {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50vh;

  span {
    color: var(--neutral6);
    font-weight: 500;
    font-size: 1.125rem;
    margin-top: var(--margin-2);
    line-height: 130%;
    letter-spacing: -0.72px;
    white-space: pre-wrap;
    text-align: center;
  }
}

.panda {
  height: 200px;
  width: 200px;
  object-fit: contain;
  object-position: center;
  mix-blend-mode: luminosity;
}
