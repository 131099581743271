.root {
  display: flex;
  padding: 0.75rem 1rem 0.75rem 1rem;
  border-bottom: 1px solid var(--neutral4);
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  cursor: pointer;
  max-width: 100%;

  &:hover {
    background: var(--neutral1p75);
  }
}

.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}

.text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  padding: 0 !important;
  border: 1px solid transparent !important;
  background: transparent !important;
  font-size: 0.93rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: -0.00938rem;
  color: var(--primary-main9) !important;

  &[disabled] {
    cursor: default;
    pointer-events: none;
    -webkit-text-fill-color: var(--primary-main9); /* Override iOS / Android font color change */
    -webkit-opacity: 1; /* Override iOS opacity change affecting text & background color */
  }

  &:focus,
  .admin:hover & {
    border-color: var(--neutral3) !important;
  }
}

.tags {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
}

.actions {
  position: relative;
}
