@import 'src/styles/basics';

.content {
  text-align: center;
  font-size: 16px;
}

.buttons {
  display: flex;
  margin-top: 15px;
}

.button {
  margin-right: 5px;
  margin-left: 5px;
}

.categorySelect {
  margin-right: 15px;
}

.categoriesTypes {
  margin-bottom: 15px;
}

.parts {
  display: flex;
}

.part {
  flex: 1;
  margin-left: 40px;
  &:first-child {
    margin-left: 0;
  }
}

.checkbox {
  display: flex;
  align-items: center;
}
.checkboxLabel {
  margin-left: 5px;
}
