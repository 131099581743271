@import 'src/styles/basics';

.root {
  position: fixed;
  top: var(--header-height);
  bottom: 0;
  width: 330px;
  background: white;
  right: -330px;
  z-index: 9;
  box-shadow: -5px 10px 30px 0 rgba(0, 0, 0, 0.2);
  transition: all 500ms ease-in-out;
  overflow: scroll;
  padding: 24px;
  transform: translateX(330px);

  &.open {
    transform: translateX(-330px);
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.close {
  padding: 0 0 32px 10px;

  cursor: pointer;
}

.title {
  overflow: hidden;
  overflow-wrap: break-word;
}
