.root {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 8.25rem;
  height: 8.25rem;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  z-index: 100;
}

.rootClose {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 100;
  width: 2rem;
  height: 2rem;
  background-color: var(--neutral7);
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 1rem;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  svg {
    color: white;
  }
}

.fullExperienceWrapper {
  background-color: white;
  transform: rotate(-45deg);
  position: absolute;
  bottom: 3rem;
  left: -5rem;
  align-self: flex-end;
  padding: 0.5rem 5rem;

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  .closeIcon {
    position: absolute;
    bottom: 1.6rem;
    left: 13.3rem;
    transform: rotate(45deg);

    cursor: pointer;
    color: var(--neutral7);

    height: 1rem;

    &:hover {
      opacity: 0.8;
    }
  }

  p {
    width: max-content;
    text-align: center;
    color: var(--neutral7);
    font-size: 0.75rem;
    font-family: 'Inter', sans-serif;
  }
}

.loginButtonWrapper {
  display: flex;
  justify-content: center;
  align-items: flex-end;

  background: linear-gradient(-45deg, var(--neutral7) 50%, transparent 50%);
  width: 7rem;
  height: 7rem;
  cursor: pointer;
  border: none;
  margin: 0;
  padding: 0;
}

.loginInnerDiv {
  background-color: transparent;
  font-family: 'Inter', sans-serif;
  color: white;
  font-size: 1rem;
  font-weight: 700;
  gap: 0.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 2.5rem;
  margin-bottom: 0.5rem;
}
