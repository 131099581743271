@import 'src/styles/basics';

.root {
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;

  .select {
    width: 300px;
  }

  form {
    width: 100%;
    margin-left: 10px;

    .inputContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 20px;

      .labelContainer {
        display: flex;
        padding-bottom: 8px;
        font-size: 14px;
        line-height: 16px;

        .icon {
          align-self: center;
          color: var(--primary-main5);
          margin-left: 10px;
        }
      }

      label {
        color: $grayDark;
        font-weight: 600;
      }
    }
    .textArea {
      resize: none;
    }
  }

  .submitButton {
    margin-top: 15px;
  }
}
