.backArrow {
  color: var(--primary-main6);
  font-size: 26px;
}

.closeButtonWrapper {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: flex-start;

  button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
  }
}
