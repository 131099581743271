@import 'src/styles/basics';

.root {
  min-height: 70px;
  background: $knowledgeItemBackgroundLight;
  border-radius: 15px;
  margin: 15px 0 0;
  display: flex;
  overflow: hidden;
  align-items: center;
  cursor: pointer;

  &.light {
    background: $knowledgeItemBackgroundLight;
  }
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  margin: 14px 5px 14px 20px;
  flex: 1;

  .wrapper {
    margin-right: 20px;
    flex: 0.5;
  }

  .name {
    font-size: $itemTitleFontSize;
    line-height: 1.5;
    color: $blueDark;
    font-weight: 700;
    flex: 1;
    align-items: center;
    display: flex;
  }

  .department {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    font-style: normal;
    color: $blueDark;
  }

  .phone {
    color: var(--neutral6);
    font-weight: $itemTitleFontWeight;
  }
  .pager {
    color: var(--neutral6);
    font-weight: $itemTitleFontWeight;
  }
  .contacts {
    display: flex;
    flex-direction: column;
    flex: 1;

    .separator {
      margin: 0 10px;
      color: var(--neutral6);
      font-weight: $itemTitleFontWeight;
    }

    .email {
      color: $colorPrimary;
      font-weight: $itemTitleFontWeight;
      text-decoration-line: underline;

      a {
        color: $colorPrimary;
      }
    }
  }

  .iconWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
  }

  .buttonWrapper {
    height: 30px;
    width: 30px;
    background: $knowledgeItemIconWrapper;
    border: 0;
    margin-right: 7px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 300ms ease-in-out;

    &:hover,
    &:disabled {
      background: $knowledgeItemIconWrapperWebHover;
    }
    .actionIcon {
      color: $primary;
      font-size: 16px;
    }
  }

  .icon {
    width: 17px;
    height: 17px;
    color: var(--neutral6);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pagerIcon {
    width: 17px;
    height: 17px;
    fill: var(--neutral6);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .actionIcons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    flex-basis: 10%;
  }
}

.thumbnailWrapper {
  height: 35px;
  width: 30px;
  display: flex;
  align-content: center;
  justify-content: center;
  margin: 14px 0px 14px 20px;
}

.greyBackground {
  background: $lightGray2;
}
