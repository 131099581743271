.createBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 22rem;
  height: 9.5rem;
  border-radius: 0.25rem;
  border: 1px solid var(--neutral4);
  background: var(--neutral2);
  gap: 0.32rem;
  cursor: pointer;
  transition: all 200ms ease-in-out;

  &:hover,
  .active & {
    box-shadow: 0px 4px 20px 0px rgba(13, 20, 45, 0.2);
  }

  p {
    font-size: 0.6875rem !important;
    font-weight: 600;
    line-height: 110% !important;
    letter-spacing: -0.01375rem;
    color: var(--neutral600) !important;
  }

  .icon {
    font-size: 2.625rem;
    color: var(--neutral600);
  }
}

.createRow {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  height: 3.31rem;
  gap: 0.5rem;
  cursor: pointer;

  &:hover {
    background: var(--neutral1p75);
  }

  p {
    font-size: 0.9375rem !important;
    font-weight: 400;
    line-height: 1.125rem !important;
    letter-spacing: -0.00938rem;
    color: var(--primary-main6) !important;
  }

  .icon {
    font-size: 1.5rem;
    color: var(--primary-main6);
  }
}
