@import 'src/styles/basics';

.textTag {
  width: fit-content;
  height: 22px;
  padding: 6px 8px;
  border-radius: 10px;
  background-color: $folderTextTagBackground;
}

.largeTextTag {
  height: 24px;
  padding: 6px 10px;
}

.text {
  font-size: 13px !important;
  line-height: 0.86 !important;
  text-align: left !important;
  color: $folderTextColor !important;
  font-weight: normal !important;
}

.largeText {
  font-size: 15px !important;
  margin: 0px !important;
}
