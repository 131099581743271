.root {
  display: flex;
  width: 22rem;
  height: 9.5rem;
  border-radius: 0.25rem;
  border: 1px solid var(--neutral4);
  background: var(--neutral1);
  cursor: pointer;

  &:hover,
  .active & {
    box-shadow: 0px 4px 20px 0px rgba(13, 20, 45, 0.2);
  }
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  width: 4rem;
  border-right: 1px solid var(--neutral4);
  background: var(--neutral2);
  border-radius: 0.25rem 0 0 0.25rem;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 18rem;
  padding: 1rem 1rem 0.75rem 1rem;
  gap: 0.81rem;
}

.header {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 0.62rem;
}

.text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  flex: 1;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  word-break: break-all !important;
  padding: 0 !important;
  border: 1px solid transparent !important;
  background: transparent !important;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.1rem;
  letter-spacing: -0.01rem;
  color: var(--primary-main8) !important;

  &[disabled] {
    cursor: default;
    pointer-events: none;
    -webkit-text-fill-color: var(--primary-main8); /* Override iOS / Android font color change */
    -webkit-opacity: 1; /* Override iOS opacity change affecting text & background color */
  }

  &:focus,
  .admin:hover & {
    border-color: var(--neutral3) !important;
  }
}

.tags {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
}

.metadata {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 0.75rem;
  align-self: stretch;
  height: 1.5rem;
}
