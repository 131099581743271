@import 'src/styles/basics';

.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
}

.greyWrapper {
  min-height: 55px;
  align-items: center;
  overflow: hidden;
  border-radius: 4px;
  padding: 0;
  background: $inputEditorBackground;
}
.semiTransparentWrapper {
  background-color: rgba($white, 0.18);
  border: 1px solid rgba($white, 0.27);
}

.input {
  border: none;
  font-size: 17px;
}

.input::placeholder {
  color: $inputEditorPlaceholder;
}

.error {
  text-align: left;
  margin: 4px 0;
  color: var(--red);
}
