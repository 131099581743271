@import 'src/styles/basics';

.root {
  padding: $page-padding;
  width: calc(100vw - 5rem);
  display: flex;
  flex-direction: column;
  flex: 1;
}

.header {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 32px;

  .button {
    margin-left: 24px;
    padding: 0px 16px;
  }
}

.input {
  border-radius: 10px;
  margin-left: auto;
  width: 300px;
  height: 40px;
  padding: 0 10px;
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.62rem;
}

.results {
  display: flex;
  flex-direction: column;

  h4 {
    font-size: 1rem !important;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.01rem;
    color: var(--primary-main8);
  }

  h5 {
    font-size: 1rem !important;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.01rem;
    color: var(--primary-main8);
  }
}

.filterWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;

  & > div {
    padding-bottom: 0 !important;
  }
}

.search {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
  flex: 1;
}

.row {
  label {
    vertical-align: middle !important;
  }

  &:hover {
    cursor: pointer;
  }
}

.table {
  position: absolute;
  width: 100%;
  height: 100%;
}

.tableContainer {
  position: relative;
  flex: 1;

  th {
    white-space: nowrap;
    align-items: center;
    align-content: center;
  }
}

.backTop {
  margin-right: var(--margin-2);
}
