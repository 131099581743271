@import 'src/styles/basics';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px;
  border-color: $drawerBorderColor;
  margin-bottom: 10px;
  margin-right: -20px;
  padding-right: 10px;

  h5 {
    color: $primary;
    font-size: 12px;
    font-weight: normal;
    align-self: center;
    cursor: pointer;
  }
}

.container {
  display: flex;
  align-items: center;
  padding-bottom: 8px;
}

.icon {
  height: 15px;
  width: 15px;
  margin-right: 5px;
}

.title {
  color: $buttonColor;
  font-weight: $subHeaderFontWeight;
  font-size: $subHeaderFontSize;
  margin-bottom: 0;
}

.tagContainer {
  margin-bottom: 20px;
  color: $buttonColor;

  h4 {
    color: $primary;
    font-size: 15px;
    font-weight: normal;
  }
}

.item {
  display: flex;
  align-items: center;
  margin: 5px 0 5px 0;
}

.checkbox {
  margin-right: 5px;
}

.tagKey {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 5px;
}

.showButton {
  font-size: 12px;
  color: $primary;
  cursor: pointer;
}
