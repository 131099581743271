.root {
  padding-bottom: 0.25rem;
}

.header {
  display: flex;
  padding: var(--margin-2) 0;
  gap: var(--margin-2);
}

.content {
  padding: 1rem;

  ol,
  ul {
    list-style-type: revert;
    padding-inline-start: 1rem;
  }

  p,
  span,
  li {
    margin-block-end: 0.1rem;
  }
}

.sectionTitleActions {
  display: flex;
  gap: 0.25rem;
  align-items: center;
}

.fields {
  display: flex;
  flex-direction: column;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
