@import 'src/styles/basics';

.root {
  border: 1px solid $buttonColor;
  background: transparent;
  letter-spacing: 0.19;
  padding: 0 10px;
  color: $buttonColor;
  flex: 1;
  transition: 300ms all ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(:disabled) {
    &:hover {
      color: $white;
      background: $buttonColor;
    }

    &:active {
      transform: scale(0.95);
    }
  }
  &:disabled {
    opacity: 0.4;
  }
}

// styles
.danger {
  background: $buttonStyleDangerBackground;
  border-color: $buttonStyleDangerBackground;
  color: $buttonStyleDangerText;

  &:not(:disabled) {
    &:hover {
      background: $buttonStyleDangerBackgroundHover;
      border-color: $buttonStyleDangerBorderHover;
      color: $buttonStyleDangerTextHover;
    }
  }
}

.primary {
  background: var(--primary-main5);
  border-color: var(--primary-main5);
  color: $buttonStylePrimaryText;

  &:not(:disabled) {
    &:hover {
      background: $buttonStylePrimaryBackgroundHover;
      border-color: $buttonStylePrimaryBorderHover;
      color: $buttonStylePrimaryTextHover;
    }
  }
}

.primary-overlay {
  background: $buttonStylePrimaryOverlayBackground;
  border-color: $buttonStylePrimaryOverlayBackground;
  color: $buttonStylePrimaryOverlayText;
  &:not(:disabled) {
    &:hover {
      background: $buttonStylePrimaryOverlayBackground;
      border-color: $buttonStylePrimaryOverlayBorderHover;
      color: $buttonStylePrimaryOverlayTextHover;
    }
    &:active,
    :visited {
      background: $buttonStylePrimaryOverlayBackground;
      border-color: $buttonStylePrimaryOverlayActiveBorderHover;
      color: $buttonStylePrimaryOverlayTextHover;
    }
  }
}

.secondary {
  background: $buttonStyleSecondaryBackground;
  border-color: var(--primary-main5);
  color: $buttonStyleSecondaryText;

  &:not(:disabled) {
    &:hover {
      background: $buttonStylePrimaryBackgroundHover;
      border-color: $buttonStylePrimaryBorderHover;
      color: $buttonStylePrimaryTextHover;
    }
  }
}

.action {
  background: rgba($white, 0.3);
  border-color: rgba($white, 0.3);
  color: $white;
}

.darkAction {
  border: 1px solid rgba($black, 0.3);
  background: $veryDarkGray;
  color: $white;
  min-width: fit-content !important;
}

// sizes
.large {
  font-size: 17px;
  height: 50px;
  margin: 20px 0;
  border-radius: 10px;
}

.medium {
  font-size: 15px;
  height: 40px;
  margin: 10px 0;
  border-radius: 10px;
}

.small {
  font-size: 16px;
  line-height: 17px;
  height: 34px;
  font-weight: 600;
  margin: 10px 0;
  padding: 8px 14px 8px 14px;
  min-width: 90px;
  border-radius: 5px;
}
