@import 'src/styles/basics';

.root {
  min-width: 850px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $searchText;
}

.container {
  display: flex;
  justify-content: center;
  width: 800px;
}

.owner {
  min-width: 430px;
}

.column {
  display: flex;
  flex-direction: column;
  padding: 15px;
  flex: 1;
  min-width: 50%;
}

.searchWrapper {
  background: $lightGray2;
  height: 60px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.input {
  border: 0;
  border-radius: 10px;
  background: transparent;
  padding-left: 30px;
  color: $searchText;
  width: 100%;
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.button {
  margin-right: 20px;
  margin-left: 20px;
  max-width: 200px;
}

.notFound {
  min-height: min-content;
}

.searchList {
  max-height: 250px;
  margin-top: 5px;
  overflow-y: scroll;
}

.selectList {
  max-height: 360px;
  margin-top: 5px;
  overflow-y: scroll;
}
