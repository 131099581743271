@import 'src/styles/basics';

.data {
  flex-wrap: wrap;
}

.textArea {
  min-height: 300px;
  max-height: 500px;
}

.contentExpiryDateGood {
  color: $contentExpiryDateGood;
}

.contentExpired {
  color: $contentExpired;
}

.buttons {
  flex: 1;
  display: flex;
  align-items: center;
}
