@import 'src/styles/basics';

.root {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2em;
}

.panda {
  height: 12.5rem;
  width: 12.5rem;
  object-fit: contain;
  object-position: center;
  mix-blend-mode: luminosity;
}
