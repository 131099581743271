@import 'src/styles/basics';
.root {
  display: flex;
  flex-direction: column;
  padding: $page-padding;
}

.header {
  display: flex;
  flex-shrink: 1;
  justify-content: space-between;
}

.searchWrapper {
  flex: 0 0;
}

.searchHeader {
  margin-bottom: 0.25rem;
}

.search {
  display: flex;
  width: 19rem;
  height: 3rem;
  border-radius: 0.5rem;
  border: 1px solid var(--neutral4);
  background: var(--neutral1);

  color: var(--gray-dark) !important;

  font-size: 1rem !important;
  font-weight: 500;
  line-height: 115%;
  letter-spacing: 0.0125rem;
}

.upperTitle {
  color: var(--primary-main6);
  font-weight: 700;
  margin-bottom: 0.35rem;
  display: block;
}

.breadcrumbs {
  display: flex;
  font-family: Inter;
  color: var(--primary-main9);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.1rem;
  letter-spacing: -0.01rem;
  align-items: center;
  margin-bottom: 0.5rem;
  flex-wrap: wrap;

  .separator {
    color: var(--neutral6);
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.00625rem;
  }

  a {
    color: var(--primary-main6);
    font-weight: 700;

    &:hover {
      color: var(--primary-main6);
      background-color: transparent;
    }
  }
}
