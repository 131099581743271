@import 'src/styles/basics';

.selectorContainer {
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
}

.columnGroup {
  margin: 5px 0 0 0;
}

.selectorGroup {
  display: flex;
  flex-direction: column;
  width: 48.5%;
}

.selector {
  width: 100%;
  margin: 5px 0px;
  border-radius: 4px;
}
