@import 'src/styles/basics';

.root {
  flex: 1;
}

.toggle {
  gap: var(--margin-1);
  display: flex;
  padding: var(--margin-2) 0;
}

.disabled {
  opacity: 0.4;
}

.popconfirm {
  max-width: 25rem;
}

.label {
  font-weight: 400;
}

.restrictions {
  padding-top: var(--margin-2);
}

.team {
  padding: 0 var(--margin-1);
}
