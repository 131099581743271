@import 'src/styles/basics';

.root {
  padding: $page-padding;
  display: flex;
  flex-direction: row;
  flex: 1;
}

.loader {
  flex: 1;
}

.searchResults {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  flex: 1;

  h5 {
    padding: 10px 0 0 5px;
    font-weight: 400;
  }
}

.extendedResultsHeader {
  margin-top: 24px;
}

.extendedResults {
  display: flex;
  margin: 10px 0;
  margin-bottom: 0;
  align-items: center;
  border-radius: $item-border-radius;
  justify-content: center;

  span {
    color: var(--neutral6);
    font-size: 16px;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.01125rem;
  }
}

.filterBox {
  padding: $page-padding;
  margin: 90px 40px 0px 40px;
  background: $pageBackground;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  width: 20vw;
  height: fit-content;
}

.emptyRoot {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.emptyHeader {
  color: $searchEmptyScreenHeader;
  font-size: 22px;
  font-weight: 300;
  margin-bottom: 50px;
}

.emptyImage {
  object-fit: contain;
  object-position: center;
  width: 12rem;
}

.admissionBox {
  display: flex;
  transition: all 200ms ease-in-out;
  position: relative;
  flex-direction: row;
  flex-wrap: wrap;
  width: 53vw;

  &.inActive {
    opacity: 0.5 !important;
  }

  &:hover,
  &.active {
  }
}

.notFound {
  margin-top: 45px;
  color: $primary;
  font-size: 22px;
  font-weight: 300;
  text-align: center;
}

.upToDateLink {
  margin-top: 20px;
  color: $black;
  text-align: left;
  font-size: 15px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.15px;

  a,
  span {
    cursor: pointer;
    color: $colorPrimary;
  }
}

.marginTop {
  margin-top: 5px;
}

.divider {
  background: var(--neutral5);
  margin: 0;
}

.arrowContainer {
  height: 16px;
  width: 16px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: var(--neutral5);
  &.active {
    background: #193f6a;
  }
}
.arrow {
  color: #fff;
  display: flex;
  width: 14px;
  height: 17px;
  flex-direction: column;
  justify-content: center;

  &.active {
    transform: rotate(90deg);
  }
}
