@import 'src/styles/basics';

.uploadIcon {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 13px;

  img {
    height: 40px;
    margin-right: 10px;
  }
  h4 {
    color: $primary;
    font-size: 13.5px;
    font-weight: 400;
    margin: 0;
    line-height: 18px;
  }
  h4.uploadTitle {
    font-weight: 700;
  }
}

.fileUploadWrapper {
  background-color: #9e9e9e;
  margin-top: 6px;
  height: 70vh;
  display: flex;
  flex-direction: column;
}

.uploadFile {
  display: flex;
  width: 100%;
  align-items: center;
  min-height: 55px;
  margin: 0;
  background-color: $white;
  border: 2px dashed transparent;
  cursor: pointer;
}

.externalLinkInput {
  flex: 0;
  padding: 0 1px;
}

.hiddenContent {
  position: absolute;
  bottom: 0;
  z-index: -1;
  opacity: 0;
}

.headerActionsButton {
  white-space: normal;
  height: auto;
}
