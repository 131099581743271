@import 'src/styles/basics';

.addPopup {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgba($darkGrey, 0.6);
  position: absolute;
  background-color: $white;
  z-index: 5;
  margin: 5px 0 0 10px;
  color: $primary;
}

.close {
  height: 10px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.section {
  display: flex;
  flex-direction: row;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 10px;
  width: 100%;

  h5 {
    font-size: 14px;
    margin: 0;
  }
  h6 {
    font-size: 12px;
  }

  &:hover {
    background-color: $veryLightBlue;
  }
}

.icon {
  width: 40px;
  margin-right: 10px;
}

.smallerIcon {
  width: 35px;
  margin: 0 12px 0 3px;
}
