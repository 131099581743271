.root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  gap: 10rem;
}

.header {
  display: flex;
  flex-direction: column;
  padding: 4.7rem;

  .subtitle {
    color: var(--primary-main9);
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.07831rem;
    padding-bottom: 0.5rem;
  }

  .title {
    color: var(--primary-main8);
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -0.03044rem;
    padding-bottom: 2.57rem;
  }

  .message {
    color: var(--black);
    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;
    max-width: 35rem;
  }
}

.imageContainer {
  display: flex;

  .background {
    mix-blend-mode: luminosity;
    width: 30rem;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .panda {
    position: absolute;
    bottom: 1rem;
    height: 30.75rem;
    transform: translateX(-33%);
  }
}
