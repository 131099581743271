@import 'src/styles/basics';

.buttonWrapper {
  border: 0;
  margin-right: 7px;
  border-radius: 50%;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 300ms ease-in-out;

  &:hover,
  &:disabled {
    background: none;
  }
  .actionIcon {
    color: var(--primary-main6);
    font-size: 16px;
  }
}

.actionIcons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;
  flex-basis: 10%;
  margin-left: 8px;
}

.transparentBackground {
  background: transparent;
}
