@import 'src/styles/basics';

.root {
  align-items: center;
  padding: var(--margin-1) 0;
  height: 60px;
}

.buttonWrapper {
  margin: 0;
  padding: 0;
  background: transparent;
  border: 0;
  transition: 300ms opacity ease-in-out;
}

.backArrow {
  color: var(--primary-main6);
  font-size: 26px;
}

.closeButtonWrapper {
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex: 1;
  align-items: flex-start;

  button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
  }
}

.actionsWrapper {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: flex-end;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
