// Colors
$black: #000000;
$white: #ffffff;
$transparent: transparent;
$peachyPink: #ff8080;
$veryLightGray: #c3c3c3;
$veryLightGray2: #e8e8e8;
$veryLightGray3: #e3e3e3;
$grayLight: #787878;
$grayLight1: #e4e4e4;
$darkGrey: #aaaaaa;
$darkSlateBlue: #193f6a;
$opacityDarkSlateBlue: #6f89a6;
$LighterGray: #fafafa;
$lightGray: #f8f8f8;
$lightGray2: #f5f5f5;
$lightGray3: #bababa;
$lightGray3-40: rgba($lightGray3, 0.4);
$lightDarkGray: #f0f0f0;
$greenishCyan: #34f5cf;
$greenishCyanTwo: #31f4cf;
$brownGrey: #989898;
$lightSeaGreen: #2ecc71;
$veryLightBlue: #edf7ff;
$veryLightBlue2: #dbf0ff;
$lightBlue4: #a0e1f6;
$redMedium: #d6473e;
$blueDark: #2b5889;
$colorPrimary: #1677ff;
$grayMediumLight: #e1e1e1;
$bluePale: #e8f2fd;
$colorText: #333;
$grey: #7b8ba4;
$veryDarkGray: #3e3e3e;
$grayDark: #5d5d5d;

// Color Schema
$primary: $darkSlateBlue;
$tertiary: $greenishCyanTwo;
$error: $peachyPink;
$iconColor: #1677ff;

// Theme
$pageBackground: $white;
$fontColor: $black;
// Input
$inputBorderColor: $veryLightGray;
$inputPlaceholder: $veryLightGray;
$inputEditorBackground: $lightGray2;
$inputEditorPlaceholder: $veryLightGray;
$inputErrorColor: $peachyPink;

$buttonColor: $darkSlateBlue;
$otpDigitsInputBackground: $lightGray;
$screenBackground: $lightDarkGray;
$drawerBackground: $white;
$drawerBackgroundBorder: $lightGray3-40;
$drawerBorderColor: $veryLightGray2;
$drawerBorderColorItem: $lightGray3-40;
$drawerTextColor: $black;
$drawerVersion: $darkGrey;
$tabBarBackground: $LighterGray;
$tabBarBackgroundActive: $lightGray2;
$tabBarIconDefaultColor: $darkSlateBlue;
$headerIcon: $white;
$modalBackground: $white;
$contentExpiryDateGood: $lightSeaGreen;
$contentExpired: $peachyPink;
$backButton: $black;
$onboardingBackground: $darkSlateBlue;
$onboardingController: $white;
$onboardingHeader: $greenishCyanTwo;
$onboardingContent: $white;
$searchInput: $veryLightGray3;
$filterByTypeText: $darkSlateBlue;
$filterByTypeTextActive: $darkSlateBlue;
$searchBackButton: $darkSlateBlue;
$searchText: $darkSlateBlue;

// Content
$contentItemBackground: $white;
$contentItemTitle: $darkSlateBlue;
$imagePlaceholder: $veryLightGray3;
$boxContentTextColor: $black;
$addSectionButtonBackground: $darkSlateBlue;

// Knowledge item
$knowledgeItemBackground: $veryLightBlue;
$knowledgeItemBackgroundLight: $white;
$knowledgeItemTitle: $darkSlateBlue;
$knowledgeItemSubtitle: rgba($darkSlateBlue, 0.6);
$knowledgeItemIconWrapper: $lightGray2;
$knowledgeItemIconWrapperWeb: $white;
$knowledgeItemIconWrapperWebHover: $veryLightGray3;

// Notification Prompt
$notificationPromptBackground: $darkSlateBlue;
$notificationPromptTextColor: $white;
$notificationPromptConfirmButtonText: $white;

// Updated Indication
$updatedIndicationColor: $greenishCyan;

// Search
$searchEmptyScreenHeader: $darkSlateBlue;

// Header
$dropdownBorder: $lightGray3-40;

// Button styles
$buttonStyleDangerBackground: $peachyPink;
$buttonStyleDangerBackgroundHover: $transparent;
$buttonStyleDangerBorderHover: $black;
$buttonStyleDangerText: $black;
$buttonStyleDangerTextHover: $black;

$buttonStylePrimaryBackgroundHover: $darkSlateBlue;
$buttonStylePrimaryBorderHover: $darkSlateBlue;
$buttonStylePrimaryText: $white;
$buttonStylePrimaryTextHover: $white;

$buttonStylePrimaryOverlayBackground: #3ba5f485;
$buttonStylePrimaryOverlayBackgroundHover: $darkSlateBlue;
$buttonStylePrimaryOverlayBorderHover: $darkSlateBlue;
$buttonStylePrimaryOverlayActiveBorderHover: $white;
$buttonStylePrimaryOverlayText: $white;
$buttonStylePrimaryOverlayTextHover: $white;

$buttonStyleSecondaryBackground: $veryLightBlue2;
$buttonStyleSecondaryText: $darkSlateBlue;

$font-raleway: 'Raleway', sans-serif;
$page-padding: 1.5rem 2rem;
$dashboardPadding: 15px 40px;

// Folder
$folderHeaderBackground: $white;
$folderBackground: $veryLightBlue;
$folderTextColor: $darkSlateBlue;
$folderTextTagBackground: $veryLightBlue2;
$folderTitleFontSize: 14px;
$folderTitleFontWeight: 700;

$folderKnowledgeColor: #1493eb;
$folderLabColor: #3caeff;
$folderChecklistColor: #31f4cf;

// Global
$headerFontSize: 26px;
$modalHeaderFontSize: 20px;
$headerFontWeight: 700;
$subHeaderFontSize: 19px;
$subHeaderFontWeight: 700;
$itemTitleFontSize: 14px;
$itemTitleFontWeight: 500;
$dashboardTitleFontSize: 22px;
$dashboardTitleFontWeight: 700;

// Define sizes
$input-height: 32px;
$input-font-size: 13.5px !important;
$input-radius: 5px !important;
$input-error-margin: 4px 0 0 0;
$item-border-radius: 11px !important;
