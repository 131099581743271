@import 'src/styles/basics';

.root {
  position: relative;
  min-height: 100%;
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($white, 0.5);
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}
