@import 'src/styles/basics';

.viewSelector {
  display: flex;
  flex-direction: column;
  min-width: 15rem;
  max-width: 18rem;
}

.item {
  display: flex;
  width: 100%;
  padding: var(--margin-1) 0 0 var(--margin-1);
  min-height: 2rem;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  border-bottom: 1px solid var(--neutral4);
  justify-content: center;

  &:hover {
    background-color: var(--primary-main2);
    cursor: pointer;
  }
}

.itemSelected {
  background-color: var(--primary-main2);
}

.itemTitle {
  color: var(--primary-main6) !important;

  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: var(--margin-1);
}

.itemSelectedText {
  color: var(--blue-dark) !important;
  font-weight: 700;
}

.dates {
  color: var(--neutral6) !important;
  font-size: var(--font-size-label) !important;
}

.addRotationWrapper {
  padding-top: 1rem;
}
