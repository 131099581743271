@import 'src/styles/basics';

.favoritesIcon {
  height: 25px;
  width: 25px;
}

.buttonWrapper {
  height: 30px;
  width: 30px;
  background: $knowledgeItemIconWrapperWeb;
  border: 0;
  margin-right: 7px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 300ms ease-in-out;

  &:hover,
  &:disabled {
    background: $knowledgeItemIconWrapperWebHover;
  }
}
