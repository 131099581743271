@import 'src/styles/basics';

.root {
  padding: $page-padding;

  .header {
    display: flex;
    justify-content: space-between;
  }
  .headerTitle {
    display: flex;
    flex-direction: column;
  }
}

.paddingBottom {
  padding-bottom: 4rem;
}
