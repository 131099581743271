@import 'src/styles/basics';

.rotationView {
  flex: 1;
  overflow-y: auto;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.noOwner {
  font-weight: 700;
}

.editOwners {
  display: flex;
  flex-direction: column;
  gap: var(--margin-1);
  margin: var(--margin-1) 0;
}

.content {
  width: 100%;
}

.title {
  display: flex;
  margin-bottom: 0;
  align-items: center;
  gap: var(--margin-1);
}

.titleText {
  color: var(--primary-main8) !important;

  font-size: 1.75rem !important;
  font-style: normal;
  font-weight: 700 !important;
}

.titleIcon {
  font-size: large;
  color: var(--primary-main6);
  cursor: pointer;
}

.ownersIcon {
  margin-right: var(--margin-1);
  font-size: small;
  color: var(--primary-main6);
  margin-left: var(--margin-1);
  cursor: pointer;
}

.addNew {
  margin: var(--margin-2) 0;
}

.owners {
  margin-top: var(--margin-1);
  font-size: medium;

  display: flex;
  gap: 0.25rem;
  align-items: center;

  a {
    color: var(--primary-main6);
    font-weight: 700;
  }
}

.actionButtons {
  display: flex;
  flex-direction: row;
  gap: var(--margin-1);
  margin: var(--margin-1) 0;
}

.editName {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
