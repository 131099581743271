@import 'src/styles/basics';

.content {
  padding: var(--margin-2) 0;
}

.preview {
  word-wrap: break-word;
  padding: var(--margin-3);
  background: var(--white);
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.07);
  max-width: 500px;
  width: 100%;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  gap: var(--margin-2);
}

.main {
  display: flex;
  flex-direction: column;
  gap: var(--margin-2);

  max-width: 500px;
  width: 100%;

  label {
    font-size: var(--font-size-label);
    color: var(--neutral6);
    display: block;
    margin-bottom: var(--margin-05);
  }

  p {
    font-size: var(--font-size-label) !important;
    color: var(--neutral6);
    margin-bottom: var(--margin-05);
  }
}

.section {
  flex-direction: column;
  margin-top: var(--margin-1);
  padding-top: var(--margin-2);
  border-top: var(--neutral4) 1px solid;
}

.warning {
  white-space: pre-wrap;
  margin-bottom: var(--margin-2);
}
