.root {
  display: flex;
  width: 100%;
}

.sidebar {
  position: relative;
  overflow-y: auto;
  min-width: 9px;
  transition: width 0.3s ease-in;

  &.resizing {
    transition: none;
  }
}

.resizer {
  position: relative;
  cursor: col-resize;
  min-height: 100%;
  background-color: var(--neutral3);
  width: 4px;

  transition: background-color 0.3s ease;

  &:hover {
    background-color: var(--neutral5);
  }
  &.resizing {
    background-color: var(--neutral5);
  }

  &.visible {
    background-color: var(--neutral5);
  }
}

.collapseButton {
  position: absolute;
  top: 5rem;
  right: 50%;
  transform: translateX(50%);
  z-index: 100;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  display: none;

  .resizer:hover & {
    display: block;
  }

  .resizer.resizing & {
    display: block;
  }

  &.visible {
    display: block;
  }
}

@mixin collapseIcon {
  color: var(--neutral6);
  width: 1.5rem;
  height: 1.5rem;
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.5));
  transition: opacity 0.5s ease;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  background-color: var(--neutral3);
  border-radius: 50%;
}

.collapseIconSolid {
  @include collapseIcon;
  opacity: 1;
}

.collapseIconRegular {
  @include collapseIcon;
  opacity: 0;
}

.collapseButton:hover .collapseIconSolid {
  opacity: 0;
}

.collapseButton:hover .collapseIconRegular {
  opacity: 1;
}

.mainContent {
  flex: 1;
  overflow-y: auto;
}
