@import 'src/styles/basics';

.data {
  flex-wrap: wrap;
}

.detail {
  margin: 10px 0;
}

.contentExpiryDateGood {
  color: $contentExpiryDateGood;
}

.contentExpired {
  color: $contentExpired;
}

.contentDetails {
  white-space: pre-line;
}

.button {
  align-self: center;
}

.breadcrumbs {
  margin-top: 0 !important;
  margin-bottom: 10px;
}

.tags {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -20px;
}

.tagIcons {
  height: 45px;
  width: 45px;
}

.ownerTitle {
  font-weight: 500;
}
