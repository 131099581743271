@import 'src/styles/basics';

.content {
  display: flex;
  flex-direction: row;
  padding: 20px 0;
  border-bottom: 1px solid;
  border-color: $lightGray2;
  position: relative;

  &:last-child {
    border: 0;
  }

  .icon {
    padding: 0;
    width: 35px;
    height: 35px;
  }

  h4 {
    margin-left: 0;
    font-size: 14px;
    line-height: 1.5;
    color: $knowledgeItemTitle;
    font-weight: bold;
    display: flex;
  }

  .subtitle {
    color: $opacityDarkSlateBlue;
    font-weight: normal;
    margin-right: 0;
  }
}

.hour {
  top: 10px;
  right: 0;
  position: absolute;
  color: black;
  font-size: 12px;
}

.main {
  margin-top: 5px;
  flex-direction: column;
  margin-left: 15px;
}

.linkArrow {
  width: 15px;
  height: 15px;
  margin-left: auto;
  align-self: center;
}

.removeButton {
  width: 20px;
  height: 20px;
  margin-left: auto;
  align-self: center;
  cursor: pointer;
}
