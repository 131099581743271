@import 'src/styles/basics';

.root {
  overflow-y: scroll;
  height: 90%;
}
.collapseWrapper {
  margin-bottom: 2rem;
}

.fileRow {
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0rem;
  border-bottom: 1px solid var(--gray-medium-light, #e1e1e1);
  gap: 1.25rem;

  &:first-child {
    border-top: 1px solid var(--gray-black, #0c131a);
  }

  p {
    color: var(--gray-black, #0c131a);
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.2rem;
    overflow-wrap: anywhere;

    &.error {
      font-weight: 400;
    }
  }
}

.fileNameWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  gap: 2rem;
}

.errorRow {
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
}

.footer {
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 3rem 4rem;
}

.tag {
  display: inline-flex;
  padding: 0.375rem 0.5rem;
  height: 1.6rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  font-weight: 600;
}
.complete {
  color: var(--green-main, #00bf2a);
  border: 1px solid var(--green-main, #00bf2a);

  .icon {
    color: var(--green-main, #00bf2a);
  }
}
.failed {
  color: var(--red-0-red, #ff5a65);
  border: 1px solid var(--red-0-red, #ff5a65);

  .icon {
    color: var(--red-0-red, #ff5a65);
  }
}
