@import 'src/styles/basics';

.root {
  position: fixed;
  top: var(--header-height);
  bottom: 0;
  min-width: 500px;
  width: 40vw;
  background: white;
  right: -40vw;
  z-index: 9;
  box-shadow: -5px 10px 30px 0 rgba(0, 0, 0, 0.2);
  transition: all 500ms ease-in-out;
  overflow: scroll;
  padding: 50px 50px 25px;
  transform: translateX(45vw);

  &.open {
    transform: translateX(-40vw);
  }
}

.header {
  display: flex;
  font-size: $headerFontSize;
  font-weight: $headerFontWeight;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  color: var(--primary-main8);
  margin-bottom: 5px;
}

.category {
  font-weight: 300;
  font-size: 20px;
  color: var(--primary-main8);
  margin-top: 25px;
}

.close {
  position: absolute;
  right: 50px;
  top: 15px;
  cursor: pointer;
}

.icon {
  height: 25px;
  width: 25px;
}

.tagIconsWrapper {
  display: flex;
  flex-direction: column;
}

.buttonWithMargin {
  margin: 5px 5px 25px 5px;
  height: 40px;
}

.buttons {
  display: flex;
}

.tagsContainer {
  display: flex;
  margin-bottom: 5px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
