@import 'src/styles/basics';

.root {
  display: flex;
  flex-direction: row;

  aside {
    flex: 0 0 13rem;
    background: var(--neutral1);
    box-shadow: 0px -16px 34px 0px rgba(0, 0, 0, 0.08);
    min-height: 100vh;
    padding: 1rem 0;
  }

  main {
    padding: $page-padding;
    flex: 1;
  }

  .header {
    display: flex;
    justify-content: space-between;
  }
  .headerTitle {
    display: flex;
    flex-direction: column;
  }
}

.navigatorContent {
  position: sticky;
  top: calc(0.5rem + var(--header-height));
  height: calc(100vh - (0.5rem + var(--header-height)));
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  a {
    padding: 0.5rem 1.5rem;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.00938rem;
  }
}
