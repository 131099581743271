@import 'src/styles/basics';

.root {
  padding: $page-padding;
  margin: 10px 0 15px;
  display: flex;
  flex-direction: column;
  flex: 1;

  h2 {
    font-weight: $headerFontWeight;
    font-size: $headerFontSize;
    color: var(--primary-main8);
  }
}

.descriptionWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h4 {
    color: $searchEmptyScreenHeader;
    font-size: 1.2rem;
    font-weight: 300;
    margin-top: var(--margin-2);
    text-align: center;
  }
}

.doctor {
  margin-top: 40px;
  height: 200px;
  width: 300px;
  object-fit: contain;
  object-position: center;
}

.items {
  min-width: 500px;
  width: 50vw;
}
