@import 'src/styles/basics';

.root {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.greyWrapper {
  align-items: center;
  padding: 0;
}
.semiTransparentWrapper {
  background-color: rgba($white, 0.18);
  border: 1px solid rgba($white, 0.27);
}

.input {
  border: none;
  width: 100%;
}

.input::placeholder {
  color: $inputEditorPlaceholder;
}

.error {
  text-align: left;
  margin-top: 4px;
  color: var(--red-medium);
}

.optionTag {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

label {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  padding-bottom: var(--margin-05) !important;
  color: #5d5d5d;
}

.tag {
  margin: var(--margin-05);
}
