.sourcesCollapse {
  margin-top: 0.2rem;
}

.sourcesExpenderIcon {
  color: var(--primary-main6) !important;
  height: 0.8rem;
  width: 0.8rem;
}

:global {
  .ant-collapse-expand-icon {
    padding-inline-end: 0.2rem !important;
  }
}
