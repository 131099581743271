.message {
  width: 38.30031rem;
  height: 8.93656rem;
  flex-shrink: 0;
  margin-bottom: 0.81rem;
  font-size: 1rem !important;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.82rem;
  padding: 3.43rem 0;
  border-top: 1px solid var(--neutral5);
}

.organizationWrapper {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 21.74rem;
  height: 12.62rem;
  padding: 1.74rem 0.86rem;
  border-radius: 1.25rem;
  border: 1px solid transparent;

  .title {
    padding-top: 0.95rem;
    font-size: 0.9375rem;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.00938rem;
  }
  .subtitle {
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.00938rem;
  }

  & > * {
    pointer-events: none;
  }

  &:hover {
    cursor: pointer;
    border-radius: 1.25rem;
    background-color: var(--primary-main2);
    border: 1px solid var(--primary-main5);
  }
}

.organizationIcon {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 6.51281rem;
  height: 6.51281rem;
  flex-shrink: 0;
  border-radius: 1.25rem;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
}
