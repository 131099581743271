@import 'src/styles/basics';

.root {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.input {
  border: none;
  border-radius: $input-radius;
  height: $input-height;
  font-size: $input-font-size;
}

.user {
  border-bottom: 1px solid $inputBorderColor;
}

.user::placeholder {
  color: $inputPlaceholder;
}

.editor {
  background: $inputEditorBackground;
  border-radius: 20px;
  padding: 0 15px;
}

.editor::placeholder {
  color: $inputEditorPlaceholder;
}

.error {
  text-align: left;
  margin: $input-error-margin;
  color: $inputErrorColor;
}
