.section {
  margin-top: 0px;
}

.section ~ .section {
  margin-top: 40px;

  &.noMarginTop {
    margin-top: 0px;
  }
}

.sectionTitle {
  color: var(--Blue-Extra-dark, #243b54);
  letter-spacing: -0.04rem;
  margin-bottom: var(--margin-2);
}

.location {
  flex: 1;
}

.row {
  flex: 1;
  display: flex;
  flex-direction: row;

  & > :not(:first-child) {
    margin-left: 16px;
  }
  margin-top: var(--margin-1);
}

.tnc {
  display: flex;
  flex-direction: row;
  margin-top: 1.5rem;
}

.tncCheckbox {
  margin-right: 0.5rem;
}

.disclaimer {
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--gray-dark);

  a {
    color: var(--blue-medium);
    font-weight: 700;
    text-decoration-line: underline;
  }
}

.error {
  color: var(--red-medium);
}

.buttons {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  justify-content: flex-end;
}

.notificationButtons {
  display: flex;
  gap: var(--margin-2);
}

.noMargin {
  padding: 0;
}
