@import 'src/styles/basics';

.root {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.wrapper {
  background: $inputEditorBackground;
  border-radius: $input-radius;
  min-height: 55px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.input {
  border: none;
  font-size: 13px !important;
  width: 100%;
  color: $primary !important;
  font-weight: 700 !important;
}

.input::placeholder {
  color: $inputEditorPlaceholder;
}

.error {
  text-align: left;
  margin-top: 4px;
  color: $inputErrorColor;
}
