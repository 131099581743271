.root {
  background: var(--neutral3);
}

.userInput {
  display: flex;
  align-items: flex-end;
  border-bottom: 1px solid var(--neutral4);
  padding: var(--margin-1) var(--margin-2) 0;
}

.input {
  border-radius: 1.625rem;
  padding: 0.875rem 1rem;

  &::placeholder {
    font-size: var(--font-size-regular);
  }
}

.sendBtn {
  cursor: pointer;
  border: 0;
  background: #f37321;
  height: 2.75rem;
  width: 2.75rem;
  border-radius: 50%;
  color: var(--neutral2);
  font-size: 1.25rem;
  transition: all 0.2s ease-in-out;
  margin-left: var(--margin-2);
  margin-bottom: 0.875rem;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    transform: scale(0.95);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.disclaimer {
  padding: var(--margin-05);
  p {
    text-align: center;
    color: var(--neutral7) !important;
    font-size: 0.6875rem !important;
  }
}
