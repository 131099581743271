@import 'src/styles/basics';

.root {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 11;
  display: flex;
  flex-direction: row;
  background: var(--white);
}

.leftPanel {
  height: 100%;
}

.fullWidth {
  width: 100%;
  padding: 0 var(--margin-3) 1rem var(--margin-3);
  overflow-y: auto;
  height: 100vh;
  overflow-x: auto;
}

.rightContainer {
  display: flex;
  flex-direction: column;
}
