@import 'src/styles/basics';

.content {
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.widgetdItems {
  display: flex;
  padding: 0 1rem 1rem 1rem;
  border-radius: 10px;
  background: white;
  flex-direction: column;
  transition: all 200ms ease-in-out;

  &.inActive {
    opacity: 0.5 !important;
  }
}

.contentWidgetItems {
  max-height: 400px;
}

.noContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;

  h4 {
    color: $searchEmptyScreenHeader;
    font-size: 22px;
    font-weight: 300;
    margin-bottom: 30px;
  }
}

.title {
  color: var(--primary-main8) !important;
  margin: 35px 0 15px;
  font-weight: $dashboardTitleFontWeight !important;
  font-size: $dashboardTitleFontSize !important;
  background: transparent !important;
  border-color: transparent !important;
}

.pagination {
  display: flex;
  flex-direction: row;
  margin: 15px -15px -15px -15px;
  align-items: center;
  justify-content: center;
  background-color: #193f6a;
  position: relative;
  padding: 3px;

  li a {
    display: flex;
    flex: 1;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: $font-raleway;
    font-weight: 500;
    margin: 10px;
  }

  .active {
    border-radius: 10px;
    box-shadow: 0px 3px 4px -3px rgba(0, 0, 0, 1);
    background-color: #104685;
    margin: 5px;
  }
}
.nextArrow {
  position: absolute;
  right: 0;

  a {
    padding: 3px 10px;
    font-size: 20px;
  }
}
.previousArrow {
  position: absolute;
  left: 0;

  a {
    padding: 3px 10px;
    font-size: 20px;
  }
}
.disabledArrow {
  display: none;
}
