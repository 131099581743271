.root {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.loader {
  margin-top: 2rem;
}

.previewWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.fileUploadWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}

.previewImage {
  border-radius: 0.6rem;
  object-fit: contain;
}

.error {
  color: var(--red-medium);
}

.uploadWrapper {
  border: 1px solid var(--neutral4) !important;
  border-radius: 0.5rem;
  padding: 0.75rem;
  max-width: 23.5rem;
  margin: 0;

  &.errorWrapper {
    border: 1px solid var(--red) !important;
  }
}

.label {
  font-size: 0.75rem;
  color: var(--neutral6);
  padding: 0 !important;
}
