.clickAndScan {
  text-align: left;
  font-size: 0.875rem;
  font-weight: 400;
  max-width: 12.8125em;
}

.qrContainer {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 0.25rem;
  flex-wrap: wrap;
  cursor: pointer;
  padding: 0.625rem 1rem;
}

.closeModalBtn {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  display: block;
  margin: 3em 3em 3em auto; // stiky to right with some margin
}

.closeIcon {
  color: #fff;
  font-family: Font Awesome 6 Pro;
  font-size: 3rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  &:hover {
    opacity: 0.8;
  }
}

.qrCode {
  border: none;
  border-radius: 1.3em;
  padding: 1.5em;
  background: #fff;
}

.qrLarge {
  height: 21rem;
  width: 21rem;
}

dialog {
  backdrop-filter: blur(0.375em);
  min-height: 100%;
  min-width: 100%;
  border: none;
  background: linear-gradient(180deg, rgba(91, 165, 237, 0.2) 0%, rgba(91, 165, 237, 0) 100%),
    rgba(25, 63, 106, 0.83);

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;

    p {
      color: #fff;
      text-align: center;
      font-size: 1.625rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      max-width: 20em;
    }
  }

  &[open] {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation: 0.5s ease-in-out grow;
  }

  &.close {
    animation: shrink 0.5s cubic-bezier(0.19, 1, 0.22, 1) backwards;
  }
}

@keyframes grow {
  0% {
    transform: scale(0);
  }

  10% {
    opacity: 0.1;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes shrink {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  80% {
    //transform: scale(0.3);
    opacity: 0;
  }

  100% {
    transform: scale(0);
  }
}
