.scheduleUrlsTable {
  font-weight: 400;

  .actions {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }

  :global {
    .ant-table {
      border: 1px solid var(--neutral4);
      border-radius: 0.5rem;
      overflow: hidden;
    }

    .ant-table-thead > tr > th {
      padding: 0.5rem 1rem;
    }
  }
}
