.screenTitleContainer {
  padding-bottom: 1.5rem;

  h1 {
    color: var(--primary-main8) !important;
    font-size: 1.75rem !important;
    font-weight: 700 !important;
    line-height: 100% !important;
    letter-spacing: -0.8px !important;
  }

  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 15px;
  }
}

.screenTitleSubtitleContainer {
  padding-top: 8px;
}

.sectionTitleContainer {
  padding-bottom: 24px;

  h2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
  }
}
