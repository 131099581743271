.root {
  background-color: var(--primary-main2);
  border-radius: 0.25rem;
  margin-bottom: 1rem;
}

.icon {
  color: var(--primary-main9);
  height: 1rem;
  width: 1rem;
}

.collapse {
  :global {
    .ant-collapse-header {
      align-items: center !important;
    }
  }
}

.header {
  display: flex;
  padding-left: 0.5rem;
  align-items: center;
}

.actionsIcon {
  color: var(--primary-main5);
  height: 1.2rem;
  width: 0.8rem;
}

.title {
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: var(--neutral700);
}

.items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-top: -0.2rem;
}
