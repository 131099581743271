.root {
  margin: var(--margin-4);
  flex: 1;
}

.title {
  padding: 0;
  margin: 0;
}

.listAndContent {
  display: flex;
  gap: 2rem;
}

.workspaceContent {
  flex: 1;
  padding-top: 0.75rem;
}
